
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Col, Rate } from 'antd';
import { ROUTES } from 'config/enums';
import BURGER from 'assets/icons/burger-menu.png';
import { LogoDark } from 'components/ui';
import { Language } from 'components';
import HeaderRatingUser from './HeaderRatingUser';
import DropDownHeader from '../DropDownHeader';
import HeaderMenu from './HeaderMenu';
import FooterApp from '../FooterApp';

const { Header } = Layout;


const MobileHeader: React.FC<any> = ({ self, logout, restartOnboarding }) => {

    const [visibleMenu, setVisibleMenu] = useState<boolean>(false)

    const handlerMenu = (): void => setVisibleMenu((prev: boolean) => !prev)

    return (
        <>
            <Header className="header header-mobile" >
                <img onClick={handlerMenu} src={BURGER} alt="menu" />
                <Col className="header_right" xs={18} sm={10} >
                    <div className="header_right_rate">
                        <div className="header_right_rate_top">
                            <HeaderRatingUser rating={self.level} />
                            <Rate disabled defaultValue={self.level} count={3} />
                        </div>
                    </div>

                    <DropDownHeader self={self} logout={logout} restartOnboarding={restartOnboarding} />
                </Col>

            </Header>
            <div className={visibleMenu ? `burger_menu visible` : `burger_menu`}>
                <div className="burger_menu_top">
                    <NavLink className="logo" to={ROUTES.DASHBOARD}>
                        <LogoDark />
                    </NavLink>
                    <h1 onClick={handlerMenu}>X</h1>
                </div>
                <div className="burger_menu_nav" onClick={handlerMenu}>
                    <HeaderMenu />
                    <Language />
                </div>
                <div className="burger_menu_footer" onClick={handlerMenu}>
                    <FooterApp />
                </div>
            </div>
        </>
    )
}

export default MobileHeader