import { memo } from 'react';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { setReapeatOnboarding, setRun } from 'store';
import { PlatformLoader } from 'components/ui';
import { logoutAsync } from 'store/auth/auth-actions'
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const HeaderApp: React.FC = (): JSX.Element => {

    const dispatch = useAppDispatch();
    let { self } = useAppSelector(state => state.user);
    const { width } = useWindowSize();

    function logout(): void {
        dispatch(logoutAsync())
    }

    function restartOnboarding(): void {
        dispatch(setReapeatOnboarding())
        dispatch(setRun(true))
    }

    if (!self) {
        return <PlatformLoader />
    }

    return (
        <>
            {
                width > 1000
                    ? <DesktopHeader self={self} width={width} logout={logout} restartOnboarding={restartOnboarding} />
                    : <MobileHeader self={self} logout={logout} restartOnboarding={restartOnboarding} />
            }
        </>
    )
}

export default memo(HeaderApp)
