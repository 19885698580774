export { default as BlocksWrapper } from 'components/layouts/BlocksWrapper';
export { default as DropDownHeader } from 'components/layouts/DropDownHeader';
export { default as FooterApp } from 'components/layouts/FooterApp';
export { default as FooterSocialLinks } from 'components/layouts/FooterSocialLinks';
export { default as LayoutApp } from 'components/layouts/Layout';
export { default as DesktopHeader } from 'components/layouts/header/DesktopHeader';
export { default as DropDownMenu } from 'components/layouts/header/DropDownMenu';
export { default as HeaderApp } from 'components/layouts/header/HeaderApp';
export { default as HeaderRate } from 'components/layouts/header/HeaderRate';
export { default as HeaderMenu } from 'components/layouts/header/HeaderMenu';
export { default as HeaderRatingUser } from 'components/layouts/header/HeaderRatingUser';
export { default as MobileHeader } from 'components/layouts/header/MobileHeader';
