import { ReactComponent as GOOGLE_ICON } from '../assets/icons/social/google.svg';
import { ReactComponent as FACEBOOK_ICON } from '../assets/icons/social/facebook.svg';
import { ReactComponent as FACEBOOK_FOOTER_ICON } from '../assets/icons/social/facebook-footer.svg';
import { ReactComponent as INSTAGRAM_ICON } from '../assets/icons/social/instagram.svg';
import { ReactComponent as TWITTER_ICON } from '../assets/icons/social/twitter.svg';
import { ReactComponent as YOUTUBE_ICON } from '../assets/icons/social/youtube.svg';
import { ReactComponent as LINKEDIN_ICON } from '../assets/icons/social/linkedin.svg';
import { ReactComponent as EMAIL_WHITE_ICON } from '../assets/icons/social/email.svg';
import { ReactComponent as CODE_ICON } from '../assets/icons/code_icon.svg';
import { ReactComponent as LOGO_DARK_ICON } from '../assets/icons/logo-dark.svg';
import { ReactComponent as LOGO_WHITE_ICON } from '../assets/icons/logo.svg';
import { ReactComponent as EMAIL_ICON } from '../assets/icons/email_icon.svg';
import { ReactComponent as CALENDAR_ICON } from '../assets/icons/calendar.svg';
import { ReactComponent as HOME_ICON } from '../assets/icons/home.svg';
import { ReactComponent as DOLLAR_ICON } from '../assets/icons/dollar.svg';
import { ReactComponent as VERIFICATION_ERROR_ICON } from '../assets/icons/veification/verification-error.svg';
import { ReactComponent as VERIFICATION_COMPLETED_ICON } from '../assets/icons/veification/verification-completed.svg';
import { ReactComponent as VERIFICATION_PENDING_ICON } from '../assets/icons/veification/verification-pending.svg';
import { ReactComponent as DECLINE_ICON } from '../assets/icons/veification/decline.svg';
import { ReactComponent as TEST_GOOD_ICON } from '../assets/icons/test/test-good.svg';
import { ReactComponent as TEST_SORRY_ICON } from '../assets/icons/test/test-sorry.svg';
import { ReactComponent as SUCCESS_ICON } from '../assets/icons/success.svg';
import { ReactComponent as RECTANGLE_ICON } from '../assets/icons/draw/rectangle.svg'
import { ReactComponent as POLYLINE_ICON } from '../assets/icons/draw/polyline.svg'
import { ReactComponent as CUBOID_ICON } from '../assets/icons/draw/cube.svg'
import { ReactComponent as POINTS_ICON } from '../assets/icons/draw/point.svg'
import { ReactComponent as POLYGON_ICON } from '../assets/icons/draw/polygon.svg'
import { ReactComponent as DISUSSION_ICON } from '../assets/icons/discussion.svg'
import { ReactComponent as QUESTION_ICON } from '../assets/icons/question.svg'
import { ReactComponent as LOGIN_ICON } from '../assets/icons/login.svg'
import { ReactComponent as JOBS_ICON } from '../assets/icons/jobs.svg'
import { ReactComponent as EARNING_ICON } from '../assets/icons/earning.svg'
import { ReactComponent as DASHBOARD_ICON } from '../assets/icons/dashboard.svg'
import { ReactComponent as USER_ICON } from '../assets/icons/veification/user.svg';
import { ReactComponent as SMILE_ICON } from '../assets/icons/smile.svg';
import { ReactComponent as EMPTY_ICON } from '../assets/icons/empty.svg';

export {
    GOOGLE_ICON,
    CODE_ICON,
    FACEBOOK_ICON,
    LOGO_DARK_ICON,
    INSTAGRAM_ICON,
    YOUTUBE_ICON,
    TWITTER_ICON,
    LINKEDIN_ICON,
    LOGO_WHITE_ICON,
    EMAIL_ICON,
    CALENDAR_ICON,
    HOME_ICON,
    DOLLAR_ICON,
    VERIFICATION_ERROR_ICON,
    VERIFICATION_COMPLETED_ICON,
    VERIFICATION_PENDING_ICON,
    DECLINE_ICON,
    TEST_GOOD_ICON,
    EMAIL_WHITE_ICON,
    SUCCESS_ICON,
    TEST_SORRY_ICON,
    CUBOID_ICON,
    RECTANGLE_ICON,
    POLYLINE_ICON,
    POLYGON_ICON,
    POINTS_ICON,
    DISUSSION_ICON,
    QUESTION_ICON,
    LOGIN_ICON,
    FACEBOOK_FOOTER_ICON,
    JOBS_ICON,
    EARNING_ICON,
    DASHBOARD_ICON,
    USER_ICON,
    SMILE_ICON,
    EMPTY_ICON
}