import { Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import PlatformBtn from '../ui/PlatformBtn';
import { CONTACT_US_EMAIL } from '../../config/config';
import { IContactUs } from '../../config/interfaces';
import { contactUsAsync } from '../../store/account/account-actions';

interface IProps {
    visible: boolean;
    setVisible: (value: boolean) => void;
    aba_routing?: boolean;
}
const { TextArea } = Input;

interface IForm extends IContactUs {
    email_receiver: string;
}


const ContactUs: React.FC<IProps> = ({ visible, setVisible, aba_routing = false }): JSX.Element => {

    const { t } = useTranslation();
    const { self } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch()


    const contact_us_submit = (values: IForm): void => {
        let { email_receiver, ...rest } = values;
        dispatch(contactUsAsync(rest)).then(() => setVisible(false))
    }

    const email_subject = aba_routing ? 'New ABA routing' : self?.username;
    const email_message = aba_routing ? t('CONTACT_US_ROUTING_LABEL') : t('CONTACT_US.MESSAGE');
    const description = aba_routing ? t('CONTACT_US_ROUTING_DESC') : t('CONTACT_US.DESC');

    return (
        <Modal
            width={600}
            open={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            closable
            destroyOnClose={true}
        >
            <h1>{t('FOOTER.CONTACT')}</h1>
            <h4>{description}</h4>
            <Form
                name="contact-us"
                onFinish={contact_us_submit}
                layout="vertical"
            >
                <Form.Item
                    label={t('CONTACT_US.SENDER')}
                    name="email_sender"
                    initialValue={self?.email}
                >
                    <Input disabled={true} value={self?.email} placeholder={t('CONTACT_US.SENDER')} />
                </Form.Item>
                <Form.Item
                    label={t('CONTACT_US.RECEIVER')}
                    name="email_receiver"
                    initialValue={CONTACT_US_EMAIL}
                >
                    <Input disabled={true} value={CONTACT_US_EMAIL} />
                </Form.Item>
                <Form.Item
                    label={t('CONTACT_US.SUBJECT')}
                    name="email_subject"
                    initialValue={email_subject}
                >
                    <Input disabled={true} value={email_subject} />
                </Form.Item>
                <Form.Item
                    label={email_message}
                    name="email_message"
                    rules={[{ required: true, message: t('CONTACT_US.MESSAGE_VALID') }]}
                >
                    <TextArea rows={4} placeholder={email_message} />
                </Form.Item>
                <Form.Item>
                    <PlatformBtn size="full" htmlType="submit">{t('AUTH.FORGOT_PASSWORD.SEND')}</PlatformBtn>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ContactUs
