import { memo, useEffect } from "react";
import { Pagination } from 'antd';
import { useAppDispatch, usePagination } from "hooks";
import { getJobsHistoryAsync } from 'store';
import { TaskItemContainer } from "components/task";

interface IProps {
    jobs: any;
    isHistory: boolean;
}

const MyJobHistory = ({ jobs, isHistory }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const {
        paginationPage,
        setPaginationPage,
        defaultPage
    } = usePagination({ paramsActive: false, callback: (pageNumber: number) => dispatch(getJobsHistoryAsync(pageNumber)) });

    useEffect(() => {
        if (typeof defaultPage !== 'string') return
        dispatch(getJobsHistoryAsync(+defaultPage))
    }, [])

    return (
        <div>
            <TaskItemContainer jobsList={jobs.results} isHistory={isHistory} />
            <Pagination current={paginationPage} total={jobs.count} onChange={setPaginationPage} showSizeChanger={false} />
        </div>
    )
}

export default memo(MyJobHistory)
