export const storage = {
    save: (name: string, value: string) => {
        localStorage.setItem(name, value)
    },
    delete: (name: string) => {
        localStorage.removeItem(name)
    },
    edit: (name: string, value: string) => {
        storage.delete(name)
        storage.save(name, value)
    },
    get: (name: string) => {
        return localStorage.getItem(name)
    }
}