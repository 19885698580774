import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config/enums'
import { ONBOARDING_DATA } from 'config/onboarding-steps-info';
import { JOBS_ICON, EARNING_ICON, DASHBOARD_ICON } from 'utils';

const HeaderMenu: React.FC = (): JSX.Element => {

    const { t } = useTranslation()

    return (
        <>
            <NavLink to={ROUTES.DASHBOARD}><DASHBOARD_ICON /><>{t('HEADER.DASHBOARD')}</></NavLink>
            <NavLink to={ROUTES.MONEY} ><EARNING_ICON /><>{t('HEADER.MY_MONEY')}</></NavLink>
            <NavLink to={ROUTES.JOBS} className={`${ONBOARDING_DATA.STEP_4.target}`}><JOBS_ICON /><>{t('HEADER.MY_JOBS')}</></NavLink>
        </>
    )
}

export default HeaderMenu
