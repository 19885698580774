/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarCircleOutlined } from '@ant-design/icons';
import { CUBOID_ICON, POINTS_ICON, POLYGON_ICON, POLYLINE_ICON, RECTANGLE_ICON } from 'utils';

interface IProps {
    reward_per_object: { [key: string]: number }
}

interface IObject {
    price: number;
    icon: React.ReactNode;
}

// const NOT_VISIBLE = ['issue', 'id', 'project'];
const ELEMENTS = ['track_rectangle', 'track_polyline', 'track_polygon', 'track_points', 'track_cuboid', 'shape_rectangle', 'shape_polyline', 'shape_polygon', 'shape_points', 'shape_cuboid']

const TaskItemDetailPerObject = ({ reward_per_object }: IProps): JSX.Element => {

    const { t } = useTranslation();
    const OBJECTS: { [key: string]: { icon: React.ReactNode } } = {
        cuboid: { icon: <CUBOID_ICON /> },
        polygon: { icon: <POLYGON_ICON /> },
        polyline: { icon: <POLYLINE_ICON /> },
        points: { icon: <POINTS_ICON /> },
        rectangle: { icon: <RECTANGLE_ICON /> },
    }

    let copy_object = { ...reward_per_object };

    function display_price(): IObject[] {
        let arr = [] as IObject[];

        for (const key in copy_object) {
            if (copy_object[key] > 0) {
                if (ELEMENTS.includes(key)) {
                    arr.push({ price: copy_object[key], icon: OBJECTS[key.split('_')[1]].icon })
                }
            }
        }

        return arr;
    }

    return (
        <>
            <div className="job_detail_item">
                <DollarCircleOutlined />
                <p>{t('JOB.DETAIL.PRICE')}</p>
                {display_price()
                    .map((el: IObject, index: number) =>
                        <span key={index} className="job_detail_item_price">
                            <span className="job_detail_item_price_icon">{el.icon}</span>
                            <span className="job_detail_item_price_price">{el.price}$</span>
                        </span>
                    )}
            </div>
        </>
    )
}

export default memo(TaskItemDetailPerObject)