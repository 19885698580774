import { useState } from "react"

const useCaptcha = (): { isValid: boolean, onChangeCaptcha: (value: any) => void } => {

    const [isValid, setValid] = useState<boolean>(true);

    function onChangeCaptcha(value: any): void {
        if (value) {
            setValid(false)
        }
    }

    return { isValid, onChangeCaptcha }
}

export default useCaptcha
