import { urls } from "config/config";
import {
    IContactUs,
    IDashboardResponse,
    IDataTakeJob,
    ICheckActiveJobAndProject,
    IResponse,
    IJob,
    IProject,
    IProjectOverview,
    IAvailableJobsParams,
    LanguageType,
    INotification
} from "config/interfaces";
import { $api } from "services";

interface IParams extends IAvailableJobsParams {
    annotator?: boolean;
}

export default class AccountService {

    static async getDashboard() {
        let response = await $api.get<IDashboardResponse>(`${urls.account}/dashboard/`);
        return response.data
    }

    static async takeJob(data: IDataTakeJob) {
        let { job_id, ...args } = data;
        let response = await $api.patch<IJob>(`${urls.account}/job/${job_id}/update/`, args);
        return response.data
    }

    static async getJobs(params: IParams) {
        if (params.annotator) {

            let response = await $api.get<IResponse<IJob>>(`${urls.account}/job/get-all?page=${params.page}`);
            return response.data
        }

        const enrols = params.enrolls.length > 0 ? `${params.enrolls.join()}` : "";
        let response = await $api.get<IResponse<IJob>>(`${urls.account}/job/get-all?page=${params.page}&enrolls=${enrols}`);

        return response.data

    }

    static async myJobs(page: number) {
        let response = await $api.get<IResponse<IJob>>(`${urls.account}/job/working-list/?page=${page}`);
        return response.data
    }

    static async getActive() {
        let response = await $api.get<ICheckActiveJobAndProject>(`${urls.account}/job/get-active/`);
        return response.data
    }

    static async jobsHistory(page: number) {
        let response = await $api.get<IResponse<IJob>>(`${urls.account}/job-history/?page=${page}`);
        return response.data
    }

    static async jobInfo(id: number, historyJob: boolean) {
        let url = historyJob
            ? `${urls.account}/job-history/${id}/info`
            : `${urls.account}/job/${id}/info`
        let response = await $api.get(url);
        return response.data
    }

    static async contact_us(data: IContactUs) {
        let response = await $api.post(`core/contact-us/`, data);
        return response.data
    }

    static async project_enrol(id: number) {
        let response = await $api.get(`${urls.account}/project/${id}/enroll/`);
        return response.data
    }

    static async project_unenrol(id: number) {
        let response = await $api.get<IProjectOverview>(`${urls.account}/project/${id}/unenroll/`);
        return response.data
    }

    static async project_overview() {
        let response = await $api.get<IProject[]>(`${urls.account}/project/overview/`);
        return response.data
    }

    static async get_notifications(language: LanguageType) {
        let response = await $api.get<INotification[]>(`core/notification?language=${language}`);
        return response.data
    }
}