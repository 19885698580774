import { COMPLETED_TEST_VALUE } from '../config/config';

export function checkCompletedCourse(result: number | null): boolean {

    if (result === null) {
        return false
    }

    return result > COMPLETED_TEST_VALUE;

}