import { memo } from 'react';
import { Tooltip } from 'antd'
import { QUESTION_ICON } from '../../utils/icons';

interface IProps {
    text: string | React.ReactNode;
}

const PlatformTooltip: React.FC<IProps> = (props) => {
    return (
        <Tooltip
            color="#fff"
            title={<span style={{ color: '#6b6b6b' }}>{props.text}</span>}>
            <QUESTION_ICON />
        </Tooltip>
    )
}

export default memo(PlatformTooltip)
