import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Empty, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { IDataTakeJob, IJob, IUser } from 'config/interfaces';
import { JOB_STATUSES, ROUTES } from 'config/enums';
import { MOBILE_WIDTH_FOR_JOBS } from 'config/config';
import { takeJobAsync, refuseJobAsync, clearDashboard, clear_refuse_draft_job_message, setCurrentJob } from 'store';
import { TaskItemActiveJobLink, TskItemMobile, TaskItemDetail, TaskItemInfo, TaskItem, TaskItemDescription } from 'components/task';
import { PlatformLoader } from 'components/ui';
import { isReviewer, isAnnotator, isDisableDetails } from 'utils';
interface IProps {
    jobsList: IJob[];
    loading?: boolean;
    isHistory?: boolean;
}

const TaskItemContainer: React.FC<IProps> = ({ jobsList, isHistory = false }): JSX.Element => {

    const [openInfo, setOpenInfo] = useState<number | null>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [linkClick, setLinkClick] = useState<boolean>(false);
    const linkRef: any = useRef()
    const { take_job_loading, take_job_success, active_jobs, current_job, refuse_job_success, refuse_draft_job_message } = useAppSelector(state => state.account);
    const { self } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const navigate = useNavigate();
    let refuse_message = t('REFUSE_DRAFT');

    useEffect(() => {
        if (take_job_success) {
            notification.success({
                message: t('ALERTS.SUCCESS'),
                duration: 4,
                onClose: () => dispatch(clearDashboard())
            })
            setIsModalVisible(false)
            linkRef.current?.click()
        }
        if (refuse_job_success === true) {
            navigate(ROUTES.JOBS)
            dispatch(clearDashboard())
        }

        return () => {
            dispatch(clearDashboard())
        }
    }, [take_job_success, dispatch, refuse_job_success, navigate, t])

    useEffect(() => {
        if (refuse_draft_job_message !== null) {
            notification.error({
                message: refuse_message,
                duration: 4,
                onClose: () => dispatch(clear_refuse_draft_job_message())
            })
        }
    }, [refuse_draft_job_message, refuse_message, dispatch])

    useEffect(() => {
        return () => {
            setLinkClick(false);
            setActiveJob(null)
        }
    }, [dispatch])

    const onLinkClick = (): void => setLinkClick(true);

    function setLinkToModal(job_id: number): void {
        setActiveJob(job_id)
        setIsModalVisible(true)
    }

    function onCloseModal(): void {
        dispatch(setCurrentJob(null));
        setIsModalVisible(false);
        setLinkClick(false);
    }

    const takeJob = useCallback(() => {

        let data = {} as IDataTakeJob

        if (current_job) {
            data.job_id = current_job.id.toString()
            data[current_job.status === JOB_STATUSES.VALIDATION ? 'reviewer_id' : 'assignee_id'] = Number(self?.id)

            dispatch(takeJobAsync(data))
        }

    }, [current_job, dispatch, self]);

    const checkItIsMyJob = (id: number): boolean => {

        if (active_jobs.length > 0) {
            return active_jobs.includes(id)
        }

        return false
    }


    function setActiveJob(job_id: number | null): void {
        if (job_id === null) {
            dispatch(setCurrentJob(null));
            return
        }
        let job: IJob[] = jobsList.filter(el => el.id === job_id)
        dispatch(setCurrentJob(job[0]))
    }

    function refuseJob(): void {
        let data = {} as IDataTakeJob

        if (current_job) {
            data.job_id = current_job.id.toString()
            data[current_job.status === JOB_STATUSES.VALIDATION ? 'reviewer_id' : 'assignee_id'] = null

            dispatch(refuseJobAsync(data))
        }
    }

    const checkIsActiveJobForOpacity = useCallback((id: number): number => {
        if (!self) return 1

        let { level } = self as IUser;

        if (isReviewer(level)) {
            if (active_jobs.length === 0) return 1
            if (active_jobs.includes(id)) return 1

            return .6
        }

        if (isAnnotator(level)) {
            if (active_jobs.length === 0) return 1;

            if (active_jobs.includes(id)) return 1

            return .6
        }

        return .6

    }, [jobsList])

    let show_due_date = useMemo((): boolean => {
        if (!Boolean(jobsList)) return false;

        let is_show_due_date: boolean = false;

        if (jobsList.some(job => job.status === JOB_STATUSES.COMPLETED || job.assignee_id === self?.cvat_id)) {
            return true;
        }

        return is_show_due_date
    }, [jobsList]);

    if (jobsList?.length === 0) {
        return <Empty description={t('NO_DATA')} />
    }

    if (!self) return <PlatformLoader />

    return (
        <>
            <TaskItemDescription show_due_date={show_due_date} />
            <TaskItemActiveJobLink current_job={current_job} linkRef={linkRef} />
            {
                width > MOBILE_WIDTH_FOR_JOBS
                    ? jobsList?.map((job: IJob) => <TaskItem
                        setLinkToModal={setLinkToModal}
                        key={job.id}
                        job={job}
                        disabled={isDisableDetails(job, self, active_jobs)}
                        checkItIsMyJob={checkItIsMyJob}
                        setOpenInfo={setOpenInfo}
                        isHistory={isHistory}
                        checkIsActiveJobForOpacity={checkIsActiveJobForOpacity}
                        show_due_date={show_due_date}
                    />)
                    : jobsList?.map((job: IJob) => <TskItemMobile
                        setLinkToModal={setLinkToModal}
                        key={job.id}
                        job={job}
                        disabled={isDisableDetails(job, self, active_jobs)}
                        checkItIsMyJob={checkItIsMyJob}
                        setOpenInfo={setOpenInfo}
                        isHistory={isHistory}
                        show_due_date={show_due_date}
                    />)
            }
            {current_job && <TaskItemDetail
                loading={take_job_loading}
                onCloseModal={onCloseModal}
                takeJob={takeJob}
                linkClick={linkClick}
                onLinkClick={onLinkClick}
                checkItIsMyJob={checkItIsMyJob}
                current_job={current_job}
                jobsList={jobsList}
                isModalVisible={isModalVisible}
                refuseJob={refuseJob}
                verification_status={String(self?.verification_status)}
                isHistory={isHistory}
            />}
            {openInfo && <TaskItemInfo setOpenInfo={setOpenInfo} jobID={openInfo} openInfo={openInfo} />}
        </>
    )
}

export default TaskItemContainer;