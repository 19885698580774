import { Row } from 'antd';
import { memo } from 'react';
import { useAppSelector } from 'hooks';
import { PlatformAlert } from 'components/ui';
import { NotificationsUi } from 'components';
import { useTranslation } from 'react-i18next';

const ALLOW_TYPES = ["info", "success", "error", "warning"];

const Notifications = ({ verification_status }: { verification_status: string }): JSX.Element => {
    const { notifications } = useAppSelector(state => state.account);
    const { i18n: { language } } = useTranslation();

    const parseHTML = (htmlString: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.innerHTML;
    }

    return (
        <Row className="alerts_block">
            <NotificationsUi
                verification_status={verification_status}
            />
            {notifications.filter(notification => notification.language === language).map(notification => {
                const messageWithLinks = parseHTML(notification.message);

                return (
                    <PlatformAlert
                        key={notification.id}
                        style={{ marginBottom: 10 }}
                        message={<div dangerouslySetInnerHTML={{ __html: messageWithLinks }} />}
                        type={ALLOW_TYPES.includes(notification.type) ? notification.type : 'info'}
                    />
                );
            })}
        </Row>
    )
}


export default memo(Notifications)