import { Col, Row, Rate } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { IPropsTaskItem } from 'config/interfaces';
import { JOB_STATUSES } from 'config/enums';
import { COUNT_RATE } from 'config/config';
import { TaskItemTitle, TaskItemDate, TaskItemActionBtn } from 'components/task';
import { statusConstructor } from 'utils';

interface IProps extends IPropsTaskItem {
    checkIsActiveJobForOpacity: (id: number) => number;
}

const TaskItem: React.FC<IProps> = ({ job, setLinkToModal, disabled, checkItIsMyJob, setOpenInfo, isHistory, checkIsActiveJobForOpacity, show_due_date = true }): JSX.Element => {

    function showInfo(): boolean {

        if (job.draft === true) return false

        return (checkItIsMyJob(job.id) || job.status === JOB_STATUSES.COMPLETED)
    }

    const REWARD: number = isHistory ? job.reward : job.approximate_reward;
    const JOB_OPACITY = job.status === JOB_STATUSES.COMPLETED ? 1 : checkIsActiveJobForOpacity(job.id);

    return (
        <Row key={job.id} className="task_item" align="middle" justify="space-between" style={{ opacity: JOB_OPACITY }} >
            <Col span={2}> <b>{job.type}</b></Col>
            <Col span={4}><span className="task_item_title"><TaskItemTitle job={job} /></span></Col>
            <Col span={2}><Rate count={COUNT_RATE} disabled defaultValue={job.level_complexity} /></Col>
            <Col className="task_item_reward" span={2}>
                <b>$ {REWARD} </b >
                {showInfo() && <InfoCircleFilled onClick={() => setOpenInfo(+job.id)} />}
            </Col>
            {show_due_date && <TaskItemDate due_date={job.due_date} date_completed={job.date_completed} />}
            <Col span={2}>{statusConstructor(job.status, Boolean(job.assignee_id))}</Col>
            <TaskItemActionBtn
                setLinkToModal={setLinkToModal}
                disabled={disabled}
                checkItIsMyJob={checkItIsMyJob}
                isHistory={isHistory}
                {...job}
            />
        </Row>
    )
}

export default TaskItem;