import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { MARGIN_TOP_MAIN_LAYOUT } from '../../config/config';
import { useWindowSize } from 'hooks';
import HeaderApp from './header/HeaderApp';
import FooterApp from './FooterApp';

import '../../styles/layouts.scss'

const { Content } = Layout;

const LayoutApp: React.FC = (): JSX.Element => {

    const { width } = useWindowSize();

    return (
        <Layout style={{ minHeight: '100%' }}>
            <HeaderApp />
            <Layout>
                <Layout>
                    <Content
                        className="site-layout-background container"
                        style={{ margin: 0, marginTop: MARGIN_TOP_MAIN_LAYOUT }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
                {width > 1000 && <FooterApp />}
            </Layout>
        </Layout >
    )
}

export default LayoutApp
