import { urls } from "config/config";
import { ICreateCource, ID, ITest } from "config/interfaces";
import { $api } from "services";

export default class CourseService {

    static async getAll() {
        let response = await $api.get("account/course/");
        return response.data
    }

    static async getCourse(id: ID) {
        let response = await $api.get(`account/course/${id}/`);
        return response.data
    }

    static async createCource(data: ICreateCource) {
        let response = await $api.post("account/course/", data);
        return response.data
    }

    static async deleteCourse(id: ID) {
        let response = await $api.delete(`/account/course/${id}/`);
        return response.data
    }

    static async editCource(id: ID, data: ICreateCource) {
        let response = await $api.patch(`/account/course/${id}/`, data);
        return response.data
    }

    //TES QUESTIONS

    static async questionsCreate(data: ITest[]) {
        //Create new questionm when user using btn "on add"
        let response = await $api.post("/account/question/", data);
        return response.data
    }

    static async questionsList(id: ID) {
        let response = await $api.get(`/account/question/?course=${id}`);
        return response.data
    }

    static async questionUpdate(id: ID, data: ITest) {
        //Update item question on button "Save Question #"
        let response = await $api.patch(`/${urls.account}/question/${id}/`, data);
        return response.data
    }

    static async questionDelete(id: ID) {
        let response = await $api.delete(`/${urls.account}/question/${id}/`);
        return response.data
    }

    static async getQuestionItem(id: ID) {
        if (id) {
            let response = await $api.get(`/${urls.account}/question/${id}/`);
            return response.data
        }
    }

    static async setAnswers(data: any) {
        let { test_result_id, ...args } = data;
        let response;

        if (test_result_id) {
            response = await $api.patch(`/${urls.account}/test/${test_result_id}/`, args);
        } else {
            response = await $api.post(`/${urls.account}/test/`, args);
        }

        let result = {
            id: response.data.id,
            result: response.data.result,
            course: response.data.course,
        }

        return result
    }
}