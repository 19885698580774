import React from "react";
import { Button } from "antd";
import ERRORPNG from "../assets/error.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../config/enums";
import { withTranslation } from "react-i18next";

type MyProps = {
    err?: string;
    t: any;
    children: React.ReactNode;
};
type MyState = {
    error: any;
    errorInfo: any;
};


class ErrorBoundary extends React.Component<MyProps, MyState>{
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any): void {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render(): any {
        if (this.state.errorInfo) {
            return (
                <div className="errorboundary">
                    <h1>{this.props.t("ERRORS.WRONG")}</h1>
                    <img src={ERRORPNG} alt="error" />
                    <Button size="large" type="primary"><Link to={ROUTES.DASHBOARD}>{this.props.t("ERRORS.GO_TO")}</Link></Button>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}


export default withTranslation()(ErrorBoundary)