import { memo, useEffect } from 'react';
import { Pagination } from 'antd';
import { useAppDispatch, usePagination } from 'hooks';
import { getMyJobsAsync } from 'store';
import { TaskItemContainer } from 'components/task';

interface IProps {
    jobs: any;
}

const MyJobsListComponent = ({ jobs }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const {
        paginationPage,
        setPaginationPage,
        defaultPage
    } = usePagination({ paramsActive: false, callback: (pageNumber: number) => dispatch(getMyJobsAsync(pageNumber)) });

    useEffect(() => {
        if (typeof defaultPage !== 'string') return
        dispatch(getMyJobsAsync(+defaultPage))
    }, [])

    return (
        <div>
            <TaskItemContainer jobsList={jobs.results} />
            <Pagination current={paginationPage} total={jobs.count} onChange={setPaginationPage} showSizeChanger={false} />
        </div>
    )
}

export default memo(MyJobsListComponent)
