import { useTranslation } from 'react-i18next';
import { Tooltip, Col } from "antd"
import { JOB_STATUSES } from "config/enums"
import { MOBILE_WIDTH_FOR_JOBS, PROGRESS } from 'config/config';
import { useWindowSize } from 'hooks';
import { ONBOARDING_DATA } from 'config/onboarding-steps-info';
import { PlatformBtn } from 'components/ui';


interface IProps {
    setLinkToModal: (job_id: number) => void;
    disabled?: boolean;
    checkItIsMyJob: (id: number) => boolean;
    status: string,
    id: number,
    cvat_job_id?: number;
    isHistory: boolean;
}


const TaskItemActionBtn: React.FC<IProps> = ({ setLinkToModal, disabled, checkItIsMyJob, id, status, cvat_job_id, isHistory }): JSX.Element => {

    const { width } = useWindowSize();
    const { t } = useTranslation()
    const BTN_SIZE = 'small'

    let openBtn = (
        <PlatformBtn
            className={`${ONBOARDING_DATA.STEP_3.target}`}
            onClick={() => setLinkToModal(id ?? cvat_job_id)}
            size={BTN_SIZE}
            data-cy="open_job_button"
        >
            {t('OTHER.OPEN')}
        </PlatformBtn>
    )


    let takeBtn = (
        <PlatformBtn
            data-cy="open_button"
            disabled={disabled}
            className={`${ONBOARDING_DATA.STEP_3.target}`}
            onClick={() => setLinkToModal(id ?? cvat_job_id)}
            size={BTN_SIZE}
        >
            {t('OTHER.OPEN')}
        </PlatformBtn>
    );

    let disabled_btn = (
        <Tooltip trigger="hover" title={t('DISABLED')} color={PROGRESS.strokeColor}>
            <div style={{ width: 'fit-content' }}>
                <PlatformBtn
                    disabled={disabled}
                    size={BTN_SIZE}
                >
                    {t('OTHER.OPEN')}
                </PlatformBtn>
            </div>
        </Tooltip>
    )


    const anyJob = !checkItIsMyJob(id ?? cvat_job_id) && status !== JOB_STATUSES.COMPLETED; // any job if not my and not completed(history) [need Take button]
    const myJob = checkItIsMyJob(id ?? cvat_job_id) || status === JOB_STATUSES.COMPLETED; // job completed or my active job  [need Open button]

    return (
        <>
            {
                width > MOBILE_WIDTH_FOR_JOBS
                    ? <>
                        {
                            anyJob && <Col span={3}>
                                {
                                    disabled
                                        ? disabled_btn
                                        : takeBtn
                                }

                            </Col>
                        }
                        {
                            (myJob || isHistory) && <Col span={3}>{openBtn} </Col>
                        }
                    </>
                    : <MobileVersion anyJob={anyJob} t={t} disabled={disabled} disabled_btn={disabled_btn} takeBtn={takeBtn} myJob={myJob} openBtn={openBtn} />
            }
        </>
    )
}

export default TaskItemActionBtn;


const MobileVersion = ({ anyJob, t, disabled, disabled_btn, takeBtn, myJob, openBtn, isHistory }: any): JSX.Element => {
    return (
        <>
            {
                anyJob && <div className="task_item_mobile">
                    <span className="task_item_mobile_key"> {t('JOB.DETAIL.OPEN_JOB')}:</span>
                    <span className="task_item_mobile_value">{disabled
                        ? disabled_btn
                        : takeBtn}</span>
                </div>
            }
            {
                (myJob || isHistory) && <div className="task_item_mobile">
                    <span className="task_item_mobile_key">{t('JOB.DETAIL.OPEN_JOB')}:</span>
                    <span className="task_item_mobile_value">{openBtn}</span>
                </div>
            }
        </>
    )
}