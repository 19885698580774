import { useEffect } from 'react';
import { ICource } from 'config/interfaces';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getQuestionItemAsync, setAnswersForResultAsync, setActiveIndex, setAnswers } from 'store';
import { BlocksWrapper } from 'components/layouts';
import { TestContent, TestFinish } from 'components/test';
import 'styles/pages.scss';

interface IProps {
    current_cource: ICource
}

const Test = ({ current_cource }: IProps): JSX.Element => {

    const { self } = useAppSelector(state => state.user);
    const { question_loading, current_question, result, activeIndex, answers, activeItem } = useAppSelector(state => state.cources);
    let ids: number = typeof current_cource.question_ids === 'undefined' ? 0 : Number(current_cource.question_ids.length);
    const dispatch = useAppDispatch()

    function onNextStep():void {
        const question_ids: any = current_cource.question_ids;

        dispatch(setActiveIndex());
        let obj = { ...answers };
        let key = question_ids[activeIndex];
        obj[key] = activeItem;

        dispatch(setAnswers(obj))

        if (activeIndex + 1 === question_ids.length) {
            dispatch(setAnswersForResultAsync({
                answers: obj,
                course: current_cource?.id,
                user: self?.id,
                test_result_id: current_cource.test_result_id
            }))
            return
        };

        dispatch(getQuestionItemAsync(question_ids[activeIndex + 1]))
    }


    useEffect(() => {
        if (activeIndex !== current_cource?.question_ids?.length) {
            (!current_question && current_cource.question_ids) && dispatch(getQuestionItemAsync(current_cource?.question_ids[activeIndex]))
        }
    }, [current_question])

    return (
        <div className="test_wrapepr">
            <BlocksWrapper className="test">
                {
                    activeIndex === ids
                        ? <TestFinish result={Number(result)} current_course_name={current_cource.name} />
                        : <TestContent
                            question_loading={question_loading}
                            current_question={current_question}
                            activeIndex={activeIndex}
                            current_cource={current_cource}
                            onNextStep={onNextStep}
                            activeItem={activeItem}
                            result={result}
                        />
                }
            </BlocksWrapper>
        </div>
    )
}

export default Test;