
import { createAsyncThunk } from '@reduxjs/toolkit';
import PayoutService from '../../services/payout-service';
import { IConfirmCode, PayoutSettingsParamsType } from "../../config/interfaces";

export const getBalanceAsync = createAsyncThunk(
    'account/balance',
    async (_, thunkApi) => {
        try {
            let response = await PayoutService.getBalance();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)

export const getMyMoneyAsync = createAsyncThunk(
    'account/get-my-money',
    async (page: number = 1, thunkApi) => {
        try {
            let response = await PayoutService.getMyMoney(page);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)
export const sendMyMoneyAsync = createAsyncThunk(
    'account/send-my-money',
    async (data: any | {}, thunkApi) => {
        try {
            let response = await PayoutService.sendMyMoney(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const payoutConfirmationAsync = createAsyncThunk(
    'account/payout-confirmation',
    async (data: IConfirmCode, thunkApi) => {
        try {
            let response = await PayoutService.payoutConfirmation(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const payoutCancelAsync = createAsyncThunk(
    'account/payout-cancel',
    async (id: number, thunkApi) => {
        try {
            let response = await PayoutService.payoutCancel(id);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const payoutResendEmailAsync = createAsyncThunk(
    'account/payout-resend-email',
    async (id: number, thunkApi) => {
        try {
            let response = await PayoutService.payoutResendEmail(id);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)
export const getPayoutSettingsAsync = createAsyncThunk(
    'account/payout-settings',
    async (option: PayoutSettingsParamsType, thunkApi) => {
        try {
            let response = await PayoutService.getPayoutSettings(option);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)