import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18N, LANGUAGE } from "./config/config";

const LANGUAGES = [LANGUAGE.UA, LANGUAGE.EN];
let local = localStorage.getItem(I18N);

let defaultLang;

if (local === LANGUAGE.UA || local === LANGUAGE.EN) {
    defaultLang = local
} else {
    defaultLang = LANGUAGE.EN
}


i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng: defaultLang,
        react: {
            useSuspense: true
        },
        fallbackLng: LANGUAGES,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;