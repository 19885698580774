import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './redux';
import { RootState } from 'store/store';
import { ONE_TIME_TOKEN, ONE_TIME_TOKEN_TIMESTAMP } from 'config/config';
import { getOneTimeTokenAsync } from 'store/auth/auth-actions';
import { storage } from 'services';

interface IResponse {
    isLoading: boolean;
    modifiedLink: string;
}
type Props = { link: string };

const useOneTimeToken = ({ link }: Props): IResponse => {

    const dispatch = useAppDispatch();
    const [modifiedLink, setModifiedLink] = useState<string>(link);
    const isLoading = useAppSelector((state: RootState) => state.auth.loading);

    useEffect(() => {
        if (!isTokenAlive()) {
            clear();
            getNewOneTimeToken()
                .then((res: any) => {
                    if(res) {
                        setModifiedLink(`${link}${res}`);
                    }
                })

        } else {
            const TOKEN = storage.get(ONE_TIME_TOKEN);
            const CVAT_TOKEN = TOKEN ? `#${TOKEN}` : "";
            setModifiedLink(`${link}${CVAT_TOKEN}`);
        }
    }, [link])


    const isTokenAlive = (): boolean => {
        const tokenTimestamp = storage.get(ONE_TIME_TOKEN_TIMESTAMP);
        const one_time_token = storage.get(ONE_TIME_TOKEN);

        if (!one_time_token || !tokenTimestamp) return false;

        if (tokenTimestamp) {
            let time_now = Date.now() + 5 * 60 * 1000; // Date new + 15 min
            return !(time_now >= +tokenTimestamp); // if false,  return true
        }

        return false;
    };
    const getNewOneTimeToken = async (): Promise<null | string> => {
        let result = null;

        const new_token = await dispatch(getOneTimeTokenAsync());

        if (Boolean(new_token.payload.one_time_token)) {
            result = new_token.payload.one_time_token
        }
        return result;
    };


    const clear = useCallback((): void => {
        localStorage.removeItem(ONE_TIME_TOKEN);
        localStorage.removeItem(ONE_TIME_TOKEN_TIMESTAMP);
    }, []);


    return {
        isLoading,
        modifiedLink
    }
}

export default useOneTimeToken