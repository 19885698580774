import { JOB_STATUSES } from "config/enums";
import { IJob, IUser } from "config/interfaces";
import { isAnnotator, isReviewer } from 'utils';

export function isDisableDetails(job: IJob, self: IUser, active_jobs: number[]): boolean {
    if (!self) return true;

    let { id, level } = self;
    let { assignee_id, status } = job;

    if (active_jobs.length > 0) {
        return true
    }

    if (isAnnotator(level)) {
        return status === JOB_STATUSES.VALIDATION
    }

    if (isReviewer(level)) {

        if (assignee_id === id) {
            return true
        }
    }

    return false
}