export function timeFormat(time: number): string {

    const minutes: number = Math.floor(time / 60);
    // const hours: number = Math.floor(time / 3600);
    const seconds: number = time - minutes * 60;
    let result: number | string = 0;

    if (minutes || seconds) return result = `${minutes} min, ${seconds} sec`

    return `${result} sec`
}