import React from 'react'
import { useTranslation } from 'react-i18next';
import { Row, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'config/enums';
import { IBalance } from 'config/interfaces';
import { currencyFormat } from 'utils';
import { LogoDark } from 'components/ui';

interface IProps {
    balance: IBalance;
    withdraw?: boolean;
    class_name?: string
}

const CardBalance: React.FC<IProps> = ({ balance, withdraw = true, class_name = '' }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    let BALANCE = balance.balance ? +balance.balance.toFixed(2) : 0
    const balanceToWithDraw = Math.floor(balance.minimum_payout - BALANCE);
    const PERCENT = (BALANCE / balance.minimum_payout) * 100

    function onClick(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault()
        navigate(ROUTES.MONEY)
    }

    return (
        <div className={`balance ${class_name}`}>

            <Row justify="space-between" align="middle">
                <LogoDark />
                <b className="balance_currency">USD</b>
            </Row>
            <div className="balance_money">

                {(balance.payouts_waiting > 0 || balance.payouts_pending > 0)
                    && <span className="balance_pending">
                        {t('MONEY.PROCESSING')} <b> {Math.round(balance.payouts_waiting + balance.payouts_pending)}$</b>
                    </span>}
                <h6>{currencyFormat(BALANCE)}</h6>
                <p>{t('DASHBOARD.CURRENT_BALANCE')}</p>
            </div>
            <Row justify="space-between" align="middle">
                <Progress
                    type="line"
                    percent={PERCENT}
                    trailColor="#009DA7"
                    strokeColor="#ffffff"
                    strokeWidth={5}
                    showInfo={false}
                />
                <div className="balance_info">
                    {
                        balanceToWithDraw >= 0
                            ? <p>$   {balanceToWithDraw} {t('DASHBOARD.MIMIAL')}</p>
                            : <p>{t('WITHDRAW')}</p>
                    }
                    {withdraw && <a
                        href="_"
                        type="link"
                        onClick={onClick}
                    >{t('DASHBOARD.WITHDRAW')}</a>}
                </div>
            </Row>
        </div>
    )
}

export default CardBalance;