import { useTranslation } from "react-i18next";
import { CVAT_URL } from "config/config";
import { IJob } from "config/interfaces";
import { CheckSquareOutlined } from '@ant-design/icons';
import { PlatformBtn, PlatformTooltip } from "components/ui";
import { useOneTimeToken } from "hooks";

interface IProps {
    loading: boolean;
    linkClick: boolean;
    onCloseModal: () => void;
    takeJob: () => void;
    current_job: IJob;
    myJob: boolean;
}

const TaskItemDetailStatusAction = ({ myJob, onCloseModal, current_job, loading, takeJob, linkClick }: IProps): JSX.Element => {

    const { t } = useTranslation();
    let URL = `${CVAT_URL}tasks/${current_job.task_id ? current_job.task_id : current_job.cvat_task_id}/jobs/${current_job.task_id ? current_job.id : current_job.cvat_job_id}`
    const { modifiedLink } = useOneTimeToken({ link: URL })

    return (
        <div className="job_detail_item">
            <CheckSquareOutlined />
            {myJob ? <p> {t('JOB.DETAIL.OPEN_JOB')}</p> : <p> {t('JOB.DETAIL.TAKE_JOB')}</p>}
            <PlatformTooltip text={t('ALERTS.TOOLTIP')} />
            {myJob
                ? <PlatformBtn
                    size="middle"
                    style={{ marginRight: 20, marginLeft: 20 }}
                    onClick={onCloseModal}
                >
                    <a
                        href={modifiedLink}
                        target="_blank"
                        rel="noreferrer"
                    >{t('OTHER.OPEN')}</a>
                </PlatformBtn>
                :
                <PlatformBtn
                    size="middle"
                    style={{ marginRight: 20, marginLeft: 20 }}
                    loading={loading}
                    onClick={takeJob}
                    disabled={!linkClick}
                    data-cy="confirm"
                >
                    {t('OTHER.CONFIRM')}
                </PlatformBtn>
            }
        </div>
    )
}


export default TaskItemDetailStatusAction;