import { useTranslation } from "react-i18next";
import { Select } from "antd"
import { IAvailableJobsParams, IProject } from "config/interfaces"
import { isReviewer } from "utils";

interface IProps {
    rating: number;
    paginationPage: number;
    setPaginationPage: (page: number) => void;
    active_projects: IProject[];
    setSelectedEnrolls: (enrolls: number[]) => void;
    selectedEnrolls: number[];
    getAveilableJobs: (params: IAvailableJobsParams) => void
}

const FiltersForReviewer = ({ rating, paginationPage, getAveilableJobs, setPaginationPage, active_projects, setSelectedEnrolls, selectedEnrolls }: IProps): JSX.Element => {

    const { t } = useTranslation()

    function onChangeFilterPeroject(active_filters: number[]): number[] {
        if (paginationPage === 1) {
            setSelectedEnrolls(active_filters)
            getAveilableJobs({ enrolls: active_filters, page: 1 })

            return active_filters
        }
        setSelectedEnrolls(active_filters)
        setPaginationPage(1);

        return active_filters
    }

    return (
        <>
            {isReviewer(rating as number) && <>
                <Select
                    placeholder={t('SELECT_PROJECT')}
                    mode="multiple"
                    allowClear
                    style={{ width: 'auto', minWidth: 200, marginRight: 20 }}
                    value={selectedEnrolls}
                    onChange={onChangeFilterPeroject}
                    options={active_projects.map((project: IProject) => ({ value: project.id, label: `${project.name} #${project.id}` }))}
                />
            </>}
        </>
    )
}

export default FiltersForReviewer