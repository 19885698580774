import { createSlice } from "@reduxjs/toolkit";
import { IStepOnboarding } from "../../config/interfaces";
import { STEPS_DATA_EN, STEPS_DATA_UA } from './steps-data';
import { statusOnboardingAsync } from '../user/user-actions';

interface IState {
    steps?: IStepOnboarding[];
    stepIndex: number;
    run: boolean;
    repeat_oboarding: boolean;
    finish_onboarding: boolean;
    steps_ua: any,
    steps_en: any,
}


const INITIAL_STATE: IState = {
    repeat_oboarding: false,
    stepIndex: 0,
    run: false,
    steps_ua: STEPS_DATA_UA,
    steps_en: STEPS_DATA_EN,
    finish_onboarding: false
}

export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: INITIAL_STATE,
    reducers: {
        setStepIndex(state, action) {
            state.stepIndex = action.payload
        },
        setRun(state, action) {
            state.run = action.payload
        },
        setReapeatOnboarding(state) {
            state.repeat_oboarding = true
        },
        disableRepeatOnboarding(state) {
            state.run = false
            state.repeat_oboarding = false
            state.stepIndex = 0
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(statusOnboardingAsync.fulfilled, (state) => {
                state.run = false;
            })
            .addCase(statusOnboardingAsync.rejected, (state) => {
                state.run = false;
            });
    }
})

export const { setStepIndex, setRun, setReapeatOnboarding, disableRepeatOnboarding } = onboardingSlice.actions
export default onboardingSlice.reducer