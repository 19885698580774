import 'antd/dist/antd.min.css';
import { Suspense, useEffect } from 'react';
import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DURATION_ERROR } from 'config/config';
import { VERIFICATION_STATUS } from 'config/enums';
import { clearError, selfAsync } from 'store';
import { AppRouter } from 'utils';
import 'styles/App.scss';
import { CheckDeclineStatus, ErrorBoundary } from 'components';
import { PlatformLoader } from 'components/ui';
import GoogleAuthWrapper from './google-auth-wrapper';

function App(): JSX.Element {

    const { isAuth } = useAppSelector(state => state.auth);
    const { self, loading } = useAppSelector(state => state.user);
    const { errorTitle, errorDescription } = useAppSelector(state => state.error);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (self === null && isAuth === true) {
            dispatch(selfAsync())
        }
    }, [isAuth, dispatch, self])

    useEffect(() => {
        if (errorTitle) {
            notification.error({
                message: errorTitle,
                description: errorDescription,
                duration: DURATION_ERROR
            })
        }

        return () => {
            dispatch(clearError())
        }
    }, [errorTitle, dispatch, errorDescription])

    return (
        <div className="App">
            <Suspense fallback={<PlatformLoader />}>
                {loading && <div className="loading_lang"><PlatformLoader /></div>}
                <CheckDeclineStatus status={self?.verification_status === VERIFICATION_STATUS.DECLINE} />
                <ErrorBoundary>
                    <GoogleAuthWrapper>
                        <AppRouter isAuth={isAuth} />
                    </GoogleAuthWrapper>
                </ErrorBoundary>
            </Suspense>
        </div>
    );
}

export default App;
