export function constructorErrorForQuestion(err: any): any {
    let errList = [];

    if (Array.isArray(err)) {
        errList = err
    } else {
        errList.push(err)
    }
    let newListArray: any = []
    errList.forEach(el => {
        let keys = Object.keys(el);
        keys.forEach(key => newListArray.push({ element: [key][0], description: el[key][0] }))
    })
    return newListArray
}