
import { IDateForVerification } from '../../config/interfaces';

export const parseDate = (data: string): IDateForVerification => {
    let dataOfBirthArray = data.split('-');
    let birthObject: IDateForVerification = {
        birth: {
            date: dataOfBirthArray[2],
            month: +dataOfBirthArray[1],
            year: dataOfBirthArray[0]
        }
    }
    return birthObject
}