import { Collapse, Rate } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleFilled } from '@ant-design/icons';
import { COUNT_RATE } from 'config/config';
import { IPropsTaskItem } from 'config/interfaces';
import { TaskItemTitle, TaskItemDate, TaskItemActionBtn } from 'components/task';
import { statusConstructor } from 'utils';

const { Panel } = Collapse;

const TskItemMobile: React.FC<IPropsTaskItem> = ({ job, setLinkToModal, disabled, checkItIsMyJob, setOpenInfo, selfID, isHistory, show_due_date = true }): JSX.Element => {

    const { t } = useTranslation();
    const REWARD: number = isHistory ? job.reward : job.approximate_reward;

    return (
        <Collapse>
            <Panel header={
                <>
                    <span className="task_item_title mobile"> <TaskItemTitle job={job} /></span>
                    <span className="task_item_title mobile"> <b>$ {REWARD}</b></span>
                    <span className="task_item_title mobile"> <Rate count={COUNT_RATE} disabled defaultValue={job.level_complexity} /></span>
                </>
            }
            key={job.id}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.TITLE')}:</span>
                        <span className="task_item_mobile_value"><TaskItemTitle job={job} /></span>
                    </div>
                    <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.REWARD')}:</span>
                        <span className="task_item_mobile_value">{REWARD}</span>
                        {(selfID === job.assignee_id || selfID === job.reviewer_id) && <InfoCircleFilled onClick={() => setOpenInfo(+job.id)} />}
                    </div>
                    <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.LEVEL')}:</span>
                        <span className="task_item_mobile_value"><Rate count={COUNT_RATE} disabled defaultValue={job.level_complexity} /></span>
                    </div>
                    <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.TYPE')}:</span>
                        <span className="task_item_mobile_value">{job.type}</span>
                    </div>
                    {show_due_date && <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.DUE')}:</span>
                        <TaskItemDate due_date={job.due_date} date_completed={job.date_completed} mobile />
                    </div>}
                    <div className="task_item_mobile">
                        <span className="task_item_mobile_key">{t('JOB.DESCRIPTION.STATUS')}:</span>
                        <span className="task_item_mobile_value">{statusConstructor(job.status, Boolean(job.assignee_id))}</span>
                    </div>
                    <TaskItemActionBtn
                        setLinkToModal={setLinkToModal}
                        disabled={disabled}
                        checkItIsMyJob={checkItIsMyJob}
                        isHistory={isHistory}
                        {...job}
                    />
                </div>
            </Panel>
        </Collapse >
    )
}

export default TskItemMobile;