import { useTranslation } from 'react-i18next';
import { Row, Form, Radio, Button } from 'antd';
import { ANSWERS_LIST } from 'config/config';
import { ID } from 'config/interfaces';

interface IRadioProps {
    name: ID | string;
    right_answer?: string;
    new_element?: boolean;
    index: number;
    saveItem?: (id: number) => void;
    loading?: boolean;
}

const TestFormRadio = (
    {
        name,
        right_answer = 'a',
        new_element = true,
        index,
        saveItem = () => { },
        loading = false
    }: IRadioProps): JSX.Element => {

    const { t } = useTranslation()

    return (
        <Row justify="space-between" align="middle">
            <Form.Item
                name={[name, "right_answer"]}
                label={t('COURSE.COURSE_FORM.ANSWER')}
                rules={[{ required: true, message: t('COURSE.COURSE_FORM.REQUIRED') }]}
                initialValue={right_answer}
            >
                <Radio.Group buttonStyle="solid">
                    {ANSWERS_LIST.map((el: string) => <Radio.Button key={el} value={el}>{el.toUpperCase()}</Radio.Button>)}
                </Radio.Group>
            </Form.Item>
            {!new_element && <Button loading={loading} onClick={() => saveItem(+name)}>{t('COURSE.COURSE_FORM.SAVE')}  #{index + 1}</Button>}
        </Row>
    )
}

export default TestFormRadio
