/* eslint-disable no-console */
import { useCallback, useState } from 'react';
import { notification } from 'antd';
import { useAppDispatch } from './redux';
import { getVerificationFileAsync } from 'store/verification/verification-actions';

const useGetFileFromAwsForVerification = (): any => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false)

    const getFile = useCallback(async (fn: any, file_name: string) => {
        if (!file_name) return;
        setLoading(true)
        try {
            const response = await dispatch(getVerificationFileAsync(file_name));
            const base64 = response.payload;

            fn([
                {
                    uid: file_name,
                    name: file_name,
                    status: 'success',
                    url: `data:image/png;base64,${base64}`,
                    uploaded: true,
                },
            ]);
            setLoading(false)

        } catch (error: any) {
            setLoading(false)
            fn([]);
            notification['error']({
                message: file_name,
                description: error?.message,
            });
        }
    }, [dispatch]);

    return [getFile, loading];
};

export default useGetFileFromAwsForVerification;
