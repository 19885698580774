import { IStepOnboarding } from "../config/interfaces";

interface ISteps {
    [key: string]: IStepOnboarding
}

export function constructorSteps(data: ISteps): IStepOnboarding[] {
    let arr = [];

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element: IStepOnboarding = data[key];
            arr.push({
                target: `.${element.target}`,
                content: element.content,
                title: `Step: ${key.slice(-1)} / ${Object.keys(data).length} `,
                disableBeacon: true,
            })
        }


    }
    return arr
}