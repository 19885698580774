import i18n from '../i18n';
import { BLOCK_DOMEN } from '../config/config';

export const EMAIL_PATTERN = /^[a-z0-9._%*+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,128}$/;
export const STREET_PATTERN = /^[A-Za-z,./0-9 ]+$/;
export const SWIFT_PATTERN = /^[A-Za-z 0-9*]+$/;
export const POSTAL_CODE_PATTERN = /^[A-Za-z-0-9]+$/;
export const TEXT_AND_NUMBER_PATTERN = /^[A-Za-z-0-9 ]+$/;
export const TEXT_PATTERN = /^[^\s][A-Z a-z]+$/;
export const USER_NAME_PATTERN = /^[a-z][a-z0-9_.-]*$/;
export const IBAN_PATTERN = /^UA/;
export const SWIFT_PATTERN_MAIN = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

export const rules: any = {
    required: (message: string) => ({ required: true, message }),
    typeInput: (type: string) => ({ type, message: i18n.t('EMAIL_ERROR') }),
    confirmPassword: (name?: string) => ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue(name ?? 'first-password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error(i18n.t('INPUT.RULE')));
        },
    }),
    email: (message: string) => ({ pattern: EMAIL_PATTERN, message }),
    username_length: (message: string) => ({
        validator: (_: any, value: any) => {
            if (!value) return Promise.resolve()
            return value.length > 3 ? Promise.resolve() : Promise.reject(new Error(message))
        }
    }),
    password_min: (message: string) => ({ min: 8, message }),
    password_pattern: (message: string) => ({ pattern: PASSWORD_PATTERN, message }),
    email_country: (message: string) => ({
        validator: (_: any, value: any) => {
            if (!value) return Promise.resolve()
            return value.endsWith(BLOCK_DOMEN) ? Promise.reject(new Error(message)) : Promise.resolve()
        }
    }),
    valid_text: (message: string) => ({ pattern: TEXT_PATTERN, message }),
    valid_text_and_number: (message: string) => ({ pattern: TEXT_AND_NUMBER_PATTERN, message }),
    postal_code: (message: string) => ({ pattern: POSTAL_CODE_PATTERN, message }),
    street: (message: string) => ({ pattern: STREET_PATTERN, message }),
    swift: (message: string) => ({ pattern: SWIFT_PATTERN_MAIN, message }),
    username: (message: string) => ({ pattern: USER_NAME_PATTERN, message }),
    iban: (message: string) => ({ pattern: IBAN_PATTERN, message })
}
