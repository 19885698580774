import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJob, IAccountState, IDashboardResponse, IProject, ICheckActiveJobAndProject, INotification } from 'config/interfaces';
import {
    getDashboardAsync,
    getJobsAsync,
    takeJobAsync,
    getMyJobsAsync,
    checkMyJobAsync,
    getJobsHistoryAsync,
    getJobInfoAsync,
    refuseJobAsync,
    projectEnrollAsync,
    projectUnenrollAsync,
    projectOverviewAsync,
    getNotificationsAsync
} from './account-actions';
import { updateJobStateAfterChangesJob } from "utils";

const INITIAL_STATE: IAccountState = {
    loading: false,
    error: null,
    dashboard_jobs: [],
    take_job_loading: false,
    take_job_success: false,
    refuse_draft_job_message: null,
    availalble_jobs: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    myJobs: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    jobs_history: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    job_info: {
        loading: false,
        error: null,
        results: null
    },
    current_job: null,
    refuse_job_success: false,
    availalble_projects: [],
    project_loading: false,
    active_jobs: [],
    active_projects: [],
    checkMyJobsLoading: false,
    notifications: []
}

export const accountSlice = createSlice({
    name: 'account',
    initialState: INITIAL_STATE,
    reducers: {
        clearDashboard(state) {
            state.take_job_loading = false;
            state.take_job_success = false;
            state.refuse_job_success = false;
        },
        setCurrentJob(state, action: PayloadAction<IJob | null>) {
            state.current_job = action.payload
        },
        clearErrorAccountReducer(state) {
            state.error = null
        },
        setLoadingAccountReducer(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        clear_refuse_draft_job_message(state) {
            state.refuse_draft_job_message = null
        },
        clear_available_jobs(state) {
            state.availalble_jobs.count = 0;
            state.availalble_jobs.results = [];
        },
        clear_dashboard(state) {
            state.dashboard_jobs = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDashboardAsync.fulfilled, (state, action: PayloadAction<IDashboardResponse>) => {
                state.dashboard_jobs = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getDashboardAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(takeJobAsync.pending, (state) => {
                state.take_job_loading = true;
            })
            .addCase(takeJobAsync.fulfilled, (state, { payload }) => {
                state.take_job_loading = false;
                state.take_job_success = true;

                let new_job = {
                    ...payload,
                    ...state.current_job,
                };

                state.active_jobs = [...state.active_jobs, payload.id];
                if (state.availalble_jobs.results.length > 0) {
                    state.availalble_jobs.results = state.availalble_jobs.results.map((el) =>
                        updateJobStateAfterChangesJob(el, payload)
                    );
                }

                state.dashboard_jobs = state.dashboard_jobs
                    .map((el) => updateJobStateAfterChangesJob(el, payload))
                    .filter((el) => el.id === payload.id);

                state.myJobs.count = state.myJobs.count + 1;
                state.myJobs.results = [...state.myJobs.results, new_job];
            })
            .addCase(takeJobAsync.rejected, (state) => {
                state.take_job_loading = false;
                state.take_job_success = false;
            })
            .addCase(getJobsAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getJobsAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.availalble_jobs = payload;
            })
            .addCase(getJobsAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getMyJobsAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMyJobsAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.myJobs = payload;
            })
            .addCase(getMyJobsAsync.rejected, (state) => {
                state.loading = false;
                state.active_jobs = []
                state.checkMyJobsLoading = false
            })
            // CHECK MY JOB
            .addCase(checkMyJobAsync.pending, (state) => {
                state.checkMyJobsLoading = true;
                state.loading = true;
            })
            .addCase(checkMyJobAsync.fulfilled, (state, { payload }: PayloadAction<ICheckActiveJobAndProject>) => {
                state.loading = false;
                state.active_jobs = payload.jobs;
                state.active_projects = payload.projects;

                if (payload.jobs.length === 0 && payload.projects.length === 0) {
                    state.active_projects = [];
                    state.active_jobs = [];
                    return;
                }

                if (payload.jobs.length > 0) {
                    state.active_jobs = payload.jobs;
                    state.checkMyJobsLoading = false;
                    state.active_projects = [];
                }

                if (payload.projects.length > 0) {
                    state.active_projects = payload.projects;
                    state.active_jobs = payload.jobs.length > 0 ? [...state.active_jobs, ...payload.jobs] : [...state.active_jobs];
                    state.checkMyJobsLoading = false;
                }
            })
            .addCase(checkMyJobAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getJobsHistoryAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getJobsHistoryAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.jobs_history = payload;
            })
            .addCase(getJobsHistoryAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getJobInfoAsync.pending, (state) => {
                state.job_info.loading = true;
                state.job_info.error = null;
            })
            .addCase(getJobInfoAsync.fulfilled, (state, { payload }) => {
                state.job_info.loading = false;
                state.job_info.results = payload;
                state.job_info.error = null;
            })
            .addCase(getJobInfoAsync.rejected, (state, action: PayloadAction<any>) => {
                state.job_info.loading = false;
                state.job_info.error = action.payload;
                state.job_info.results = null;
            })
            .addCase(refuseJobAsync.pending, (state) => {
                state.take_job_loading = true;
                state.refuse_job_success = false;
            })
            .addCase(refuseJobAsync.fulfilled, (state, { payload }: any) => {
                state.take_job_loading = false;

                if (payload.hasOwnProperty('status') && payload.hasOwnProperty('message')) {
                    state.take_job_success = false;
                    state.refuse_job_success = false;
                    let parse_message = JSON.parse(payload.message);
                    state.refuse_draft_job_message = parse_message.detail;
                    return;
                }

                state.active_jobs = state.active_jobs.filter((el) => el !== payload.id);
                state.take_job_success = false;
                state.refuse_job_success = true;
                state.dashboard_jobs = state.dashboard_jobs
                    .map((el) => updateJobStateAfterChangesJob(el, payload))
                    .filter((el) => el.id === payload.id);

                if (state.availalble_jobs.results.length > 0) {
                    state.availalble_jobs.results = state.availalble_jobs.results.map((el) =>
                        updateJobStateAfterChangesJob(el, payload)
                    );
                }
            })
            .addCase(refuseJobAsync.rejected, (state) => {
                state.take_job_loading = false;
                state.refuse_job_success = false;
            })
            .addCase(projectEnrollAsync.pending, (state) => {
                state.project_loading = true;
            })
            .addCase(projectEnrollAsync.fulfilled, (state, action) => {
                const filtered: IProject[] = state.availalble_projects.filter((project) => project.id === action.meta.arg);
                state.project_loading = false;
                state.active_projects = [...state.active_projects, filtered[0]];
                state.availalble_projects = [];
            })
            .addCase(projectEnrollAsync.rejected, (state) => {
                state.project_loading = false;
            })
            .addCase(projectUnenrollAsync.pending, (state) => {
                state.project_loading = true;
            })
            .addCase(projectUnenrollAsync.fulfilled, (state, action) => {
                state.active_projects = state.active_projects.filter((el) => el.id !== action.meta.arg);
                state.active_jobs = action.payload.jobs.map((job: IJob) => job.id);
                state.project_loading = false;
                state.availalble_projects = action.payload.projects;
                state.availalble_jobs = {
                    count: 0,
                    next: null,
                    previous: null,
                    results: [],
                };
            })
            .addCase(projectUnenrollAsync.rejected, (state) => {
                state.project_loading = false;
            })
            .addCase(projectOverviewAsync.pending, (state) => {
                state.project_loading = true;
                state.error = null;
            })
            .addCase(projectOverviewAsync.fulfilled, (state, action: PayloadAction<IProject[]>) => {
                state.project_loading = false;
                state.error = null;
                state.availalble_projects = action.payload;
            })
            .addCase(projectOverviewAsync.rejected, (state) => {
                state.project_loading = false;
                state.error = null;
            })
            .addCase(getNotificationsAsync.fulfilled, (state, action: PayloadAction<INotification[]>) => {
                state.notifications = action.payload.filter(notification => notification.active === true)
            })
            .addCase(getNotificationsAsync.rejected, (state) => {
                state.notifications = []
            })
    }
})

export const {
    clearDashboard,
    setCurrentJob,
    clearErrorAccountReducer,
    setLoadingAccountReducer,
    clear_refuse_draft_job_message,
    clear_available_jobs,
    clear_dashboard,
} = accountSlice.actions;
export default accountSlice.reducer;