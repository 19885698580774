import { memo } from "react";
import { useTranslation } from "react-i18next";
import { PlatformTooltip } from "components/ui";
import HeaderRatingUser from "./HeaderRatingUser";



const HeaderRate: React.FC<{ rating: number }> = ({ rating }): JSX.Element => {

    const { t } = useTranslation();

    return (
        <div className="header_right_rate">
            <div className="header_right_rate_top">
                <HeaderRatingUser rating={rating} />
            </div>
            <div className="header_right_rate_bottom">
                <div className="rate_line">
                    <span className="rate_line_percent"/>
                    <b>{t('LEVEL')}: {rating}</b>
                </div>
                <PlatformTooltip text={<span>{t('HEADER.LEVEL_TOOLTIP')} <br />{t('HEADER.LEVEL')}</span>} />
            </div>
        </div>
    )
}

export default memo(HeaderRate);