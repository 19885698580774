import { memo } from "react"
import { Col } from "antd"
import { SOCIAL_LINKS } from 'config/config';
import {
    FACEBOOK_FOOTER_ICON,
    INSTAGRAM_ICON,
    YOUTUBE_ICON,
    // TWITTER_ICON,
    LINKEDIN_ICON
} from 'utils';

const FooterSocialLinks = (): JSX.Element => {

    const STYLE = { verticalAlign: 'middle' }

    const SOCIAL_LINKS_ARR = [
        { link: SOCIAL_LINKS.facebook, icon: <FACEBOOK_FOOTER_ICON style={STYLE} /> },
        // { link: '', icon: <TWITTER_ICON style={STYLE} /> },
        { link: SOCIAL_LINKS.instagram, icon: <INSTAGRAM_ICON style={STYLE} /> },
        { link: SOCIAL_LINKS.linkedin, icon: <LINKEDIN_ICON style={STYLE} /> },
        { link: SOCIAL_LINKS.youtube, icon: <YOUTUBE_ICON style={STYLE} /> }
    ]

    return (
        <Col xl={4} md={8} xs={24} sm={12} className="footer_social">
            {
                SOCIAL_LINKS_ARR.map((el, index) => {
                    return <a key={el.link} href={el.link} target="_blank" rel="noopener noreferrer">{el.icon}</a>
                })
            }
        </Col>
    )
}

export default memo(FooterSocialLinks)