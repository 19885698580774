
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { setActiveItem, getQuestionItemAsync } from 'store';
import { ICource, ITest } from 'config/interfaces';
import { TestContentAnswers, TestContentHeader, TestContentFooter } from 'components/test';

interface IProps {
    question_loading: boolean;
    current_question: ITest | null;
    activeIndex: number;
    current_cource: ICource;
    onNextStep: () => void;
    activeItem: string;
    result: number | null;
}

const TestContent = ({ question_loading, current_question, activeIndex, current_cource, onNextStep, activeItem, result }: IProps):JSX.Element => {

    const dispatch = useAppDispatch();
    const question_ids: any = current_cource.question_ids;

    useEffect(() => {
        dispatch(setActiveItem(''))
    }, [activeIndex]);

    useEffect(() => {
        !result && dispatch(getQuestionItemAsync(question_ids[activeIndex]))
    }, [])

    return (
        <div>
            {
                <>
                    <TestContentHeader
                        title={current_cource?.name}
                        questionsCount={question_ids.length}
                        activeIndex={activeIndex}
                    />
                    <TestContentAnswers
                        current_question={current_question}
                        activeItem={activeItem}
                        setActiveItem={(value) => dispatch(setActiveItem(value))}
                        loading={question_loading}
                    />

                    <TestContentFooter
                        activeItem={activeItem}
                        onNextStep={onNextStep}
                    />
                </>
            }
        </div>
    )
}

export default TestContent;