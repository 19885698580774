
import { LANGUAGE, I18N } from 'config/config';

export const ONBOARDING_DATA_EN = {
    STEP_1: {
        target: 'step_balance',
        title: 'Balance',
        content: "Here you can find the information about your current balance. To find out more about the withdrawal process, follow How to withdraw money link.",
    },
    STEP_2: {
        target: 'step_available',
        title: "Available jobs section",
        content: "In this section you can enroll on the project, or check some available jobs for you in case you already have an active project Type: annotation for labeling job from scratch; validation for reviewing the annotated job by another remoter.",
    },
    STEP_3: {
        target: 'step_detail',
        title: "Detail",
        content: "Check job information, including price per object, annotation tool, total number of frames and the instruction by clicking on Open button. ",
    },
    STEP_4: {
        target: 'step_myjobs',
        title: "My jobs page",
        content: "Here you will find all jobs. Available jobs — jobs you can take. Active jobs — jobs you are already assigned to. Completed jobs — jobs you have completed.",
    },
    STEP_5: {
        target: 'step_personal',
        title: "Personal area",
        content: "Here you can find the information about your profile.",
    },
    STEP_6: {
        target: 'step_settings',
        title: "Verification center",
        content: "Here you can complete the verification process on this page.",
    },
    STEP_7: {
        target: 'step_guides',
        title: "Annotation guides",
        content: "We have prepared the useful guides in order to help you operate in the world of the data annotation as a pro.",
    },
}

export const ONBOARDING_DATA_UA = {
    STEP_1: {
        target: 'step_balance',
        title: 'Баланс',
        content: "Тут ви знайдете інформацію про свій поточний баланс. Щоб дізнатися більш детально про вивід грошей, перейдіть до 'Як вивести гроші?'",
    },
    STEP_2: {
        target: 'step_available',
        title: "Список задач",
        content: "Тут ви можете долучитися до проєкту, або побачити деякі доступні для вас завдання, якщо вже маєте вибраний проєкт. Тип: анотація для розмітки з нуля, валідація/схвалення для перевірки уже розміченого завдання.",
    },
    STEP_3: {
        target: 'step_detail',
        title: "Ознайомтесь",
        content: "Ознайомтесь з інформацією про завдання: винагорода за об’єкт, кількість фреймів, інструмент розмітки, та інструкція до завдання, натиснувши кнопку Відкрити.",
    },
    STEP_4: {
        target: 'step_myjobs',
        title: "Мої задачі",
        content: "Тут ви знайдете всі завдання. Доступні завдання — завдання які ви можете взяти. Активні завдання — завдання які приписані до вас. Завершені завдання — ваші завершені завдання.",
    },
    STEP_5: {
        target: 'step_personal',
        title: "Особистий куточок",
        content: "Тут ви можете знайти інформацію про свій профіль.",
    },
    STEP_6: {
        target: 'step_settings',
        title: "Цент верифікації",
        content: "На цій сторінці, ви можете закінчити процес перевірки вашого акаунта та знайти інформацію про свій профіль.",
    },
    STEP_7: {
        target: 'step_guides',
        title: "Посібники з анотування",
        content: "Ми підготували ці корисні інструкції, щоб допомогти вам стати професіоналом в розмітці даних.",
    },
}

export const ONBOARDING_DATA = localStorage.getItem(I18N) === LANGUAGE.EN ? ONBOARDING_DATA_EN : ONBOARDING_DATA_UA

