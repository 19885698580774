interface IPropAdd {
    onClick: () => void;
    count: number
}

const TestAddBtn = ({ onClick, count }: IPropAdd):JSX.Element => {
    return (
        <div className="createTest_add" onClick={onClick}>
            <b>+<span>{count}/20</span></b>
        </div>
    )
}

export default TestAddBtn