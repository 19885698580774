import { urls } from "config/config";
import {
    IConfirmCode,
    ISendMyMoneyResponse,
    ITransaction,
    IPayoutSettings,
    IMyEarningResponse,
    IBalance,
    PayoutSettingsParamsType,
} from "config/interfaces";
import { $api } from "services";

export default class PayoutService {

    static async getBalance() {
        let response = await $api.get<IBalance>(`${urls.account}/balance/`);
        return response.data
    }

    static async getMyMoney(page: number = 1) {
        let response = await $api.get<IMyEarningResponse>(`${urls.account}/my-earnings/?page=${page}`);
        return response.data
    }

    static async sendMyMoney(data: any | {}) {
        let response = await $api.post<ISendMyMoneyResponse>(`${urls.account}/my-earnings/`, data);
        return response.data
    }

    static async payoutConfirmation(data: IConfirmCode) {
        let response = await $api.post<ITransaction>(`${urls.account}/my-earnings/${data.id}/payout-confirmation/`, { security_code: data.security_code });
        return response.data
    }

    static async payoutCancel(id: number) {
        let response = await $api.get<ITransaction>(`${urls.account}/my-earnings/${id}/payout-cancell/`)
        return response.data
    }

    static async payoutResendEmail(id: number) {
        let response = await $api.get<ITransaction>(`${urls.account}/my-earnings/${id}/payout-resend-email/`)
        return response.data
    }

    static async getPayoutSettings(option: PayoutSettingsParamsType) {
        let response = await $api.get<IPayoutSettings>(`${urls.account}/my-earnings/payout-settings?bank-options=${option}`)
        return response.data
    }
}