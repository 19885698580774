import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { I18N, LANGUAGE } from 'config/config';
import UA from 'assets/icons/languages/ua.png'
import EN from 'assets/icons/languages/en.png'
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateUserLanguageAsync } from 'store';

interface ILang {
    name: string;
    icon: string;
}

const lang: ILang[] = [
    { name: LANGUAGE.UA, icon: UA },
    { name: LANGUAGE.EN, icon: EN },
]

const Language: React.FC = () => {

    const [active, setActive] = useState<string>(localStorage.getItem(I18N) ?? LANGUAGE.EN);
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { self } = useAppSelector(state => state.user);

    useEffect(() => {
        i18n.changeLanguage(active);
    }, [active])


    useEffect(() => {
        let current_language = self?.language;

        if (current_language && self) {
            setActive(current_language);
        }
    }, [self])

    let activeEl = lang.filter(el => el.name === active)[0];

    const onChangeActive = (value: string): void => {
        if (self) {
            dispatch(updateUserLanguageAsync({ language: value }))
            return
        }
        setActive(value);
    };

    return (
        <div className="lang">
            <div className="lang_active">
                <img src={activeEl.icon} alt={activeEl.name} />
            </div>
            <div className="lang_list">
                {
                    lang
                        .filter((el: ILang) => el.name !== active)
                        .map((el: ILang) => {
                            return <div key={el.name} className="lang_list_item" onClick={() => onChangeActive(el.name)}>
                                <img src={el.icon} alt={el.name} />
                            </div>
                        })
                }

            </div>
        </div>
    )
}

export default Language
