import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IFaqCategory } from "../../config/interfaces";
import { getFaqListAsync, selfAsync, statusOnboardingAsync, updateUserAsync, updateUserLanguageAsync } from './user-actions';
import { logoutAsync } from '../auth/auth-actions';
interface IUserState {
    self_loading: boolean;
    self: IUser | null;
    balance: number;
    reward_pending: number | null;
    loading: boolean;
    success: boolean;
    faq_list: IFaqCategory[];
}

const INITIAL_STATE: IUserState = {
    self_loading: false,
    self: null,
    balance: 0,
    reward_pending: null,
    loading: false,
    success: false,
    faq_list: []
}


export const userSlice = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
        clearInfo(state) {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        builder
            // Logout
            .addCase(logoutAsync.fulfilled, (state) => {
                state.self = null;
            })
            // Self
            .addCase(selfAsync.pending, (state) => {
                state.self_loading = true;
            })
            .addCase(selfAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.self_loading = false;
                state.self = action.payload;
            })
            .addCase(selfAsync.rejected, (state) => {
                state.self_loading = false;
            })
            // Status Onboarding
            .addCase(statusOnboardingAsync.fulfilled, (state) => {
                if (state.self) {
                    state.self.onboarding = true;
                }
            })
            // Update User
            .addCase(updateUserAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserAsync.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(updateUserAsync.rejected, (state) => {
                state.loading = false;
                state.success = false;
            })
            // Update User Language
            .addCase(updateUserLanguageAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserLanguageAsync.fulfilled, (state, action) => {
                let { language } = action.meta.arg;
                state.loading = false;
                state.success = true;
                if (state.self) {
                    state.self.language = language;
                }
            })
            .addCase(updateUserLanguageAsync.rejected, (state) => {
                state.loading = false;
                state.success = false;
            })
            // Get Faq List
            .addCase(getFaqListAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFaqListAsync.fulfilled, (state, action: PayloadAction<IFaqCategory[]>) => {
                state.loading = false;
                state.faq_list = action.payload;
            });
    },
})


export const { clearInfo } = userSlice.actions
export default userSlice.reducer
