import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTION_TEST_EDIT } from "../../config/enums";
import { ICource, ITest, IResultTest, ITestForEdit } from '../../config/interfaces';
import { getAllCources, createCource, editCource, getCourseById, deleteCourse, createQuestionAsync, questionsListAsync, questionUpdatePartialAsync, questionDeleteAsync, getQuestionItemAsync, setAnswersForResultAsync } from './cources-actions';

interface IState {
    current_cource: ICource | any;
    courses: ICource[] | [];
    loading: boolean;
    error: null | [];
    test_result: any;
    create_modal: boolean
    modal_loading: boolean;
    question_loading: boolean;
    question_list: [] | ITestForEdit[];
    current_question: null | ITest;
    result: null | number;
    test_visible: boolean;
    activeIndex: number;
    activeItem: string;
    answers: any;
    questions_action: {
        status: null | ACTION_TEST_EDIT.CREATE | ACTION_TEST_EDIT.DELETE | ACTION_TEST_EDIT.UPDATE,
        id: null | number
    }
}

const INITIAL_STATE: IState = {
    current_cource: null,
    courses: [],
    loading: false,
    error: null,
    create_modal: false,
    modal_loading: false,
    test_result: true,
    question_loading: false,
    question_list: [],
    current_question: null,
    result: null,
    test_visible: false,
    activeIndex: 0,
    activeItem: '',
    answers: {},
    questions_action: {
        status: null,
        id: null
    }
}

const courcesReducer = createSlice({
    name: 'cources',
    initialState: INITIAL_STATE,
    reducers: {
        clearActiveCource(state) {
            state.current_cource = null;
        },
        setCreateModalHandler(state) {
            state.create_modal = !state.create_modal
        },
        testModalHandler(state) {
            state.test_visible = !state.test_visible
        },
        onFinishTest(state) {
            state.test_visible = false;
            state.current_question = null;
            state.result = null;
            state.activeIndex = 0;
            state.activeItem = '';
            state.answers = {}
        },
        setActiveIndex(state) {
            state.activeIndex = state.activeIndex + 1
        },
        setActiveItem(state, action: PayloadAction<string>) {
            state.activeItem = action.payload
        },
        setAnswers(state, action: PayloadAction<any>) {
            state.answers = action.payload
        },
        setQuestionListCards(state, action: PayloadAction<ITestForEdit[]>) {
            state.question_list = action.payload
        },
        clearQuestionActive(state) {
            state.questions_action = {
                status: null,
                id: null
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllCources.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCources.fulfilled, (state, action: PayloadAction<[] | ICource[]>) => {
                state.courses = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getAllCources.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createCource.pending, (state) => {
                state.error = null;
                state.modal_loading = true;
            })
            .addCase(createCource.fulfilled, (state, action: PayloadAction<ICource>) => {
                state.courses = [...state.courses, action.payload];
                state.error = null;
                state.create_modal = false;
                state.modal_loading = false;
            })
            .addCase(createCource.rejected, (state, action: PayloadAction<any>) => {
                state.modal_loading = false;
                state.error = action.payload;
            })
            .addCase(editCource.pending, (state) => {
                state.error = null;
                state.modal_loading = true;
            })
            .addCase(editCource.fulfilled, (state, action: PayloadAction<ICource>) => {
                let newCourse: ICource = action.payload;
                let actualState = state.courses.map((el: ICource) => {
                    if (el.id === newCourse.id) {
                        return { ...el, ...newCourse };
                    }
                    return el;
                });
                state.current_cource = newCourse;
                state.courses = actualState;
                state.error = null;
                state.create_modal = false;
                state.modal_loading = false;
            })
            .addCase(editCource.rejected, (state, action: PayloadAction<any>) => {
                state.modal_loading = false;
                state.error = action.payload;
            })
            .addCase(getCourseById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCourseById.fulfilled, (state, action: PayloadAction<ICource>) => {
                state.current_cource = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getCourseById.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteCourse.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCourse.fulfilled, (state, action) => {
                let id: number = action.meta.arg;
                let newState = state.courses.filter((el) => el.id !== id);

                state.courses = newState;
                state.current_cource = null;
                state.error = null;
            })
            .addCase(deleteCourse.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
            })
            .addCase(createQuestionAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(createQuestionAsync.fulfilled, (state, action: PayloadAction<ITestForEdit[]>) => {
                const newQuestionList = action.payload;

                state.question_loading = false;
                state.questions_action = {
                    status: ACTION_TEST_EDIT.CREATE,
                    id: null,
                };
                let oldFilterState = state.question_list.filter((el: ITestForEdit) => !el.new_element);
                state.question_list = [...oldFilterState, ...newQuestionList];
            })
            .addCase(createQuestionAsync.rejected, (state, action: PayloadAction<any>) => {
                state.question_loading = false;
                state.error = action.payload;
            })
            .addCase(questionsListAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(questionsListAsync.fulfilled, (state, action: PayloadAction<ITest[]>) => {
                state.question_loading = false;
                state.question_list = action.payload;
            })
            .addCase(questionsListAsync.rejected, (state, action: PayloadAction<any>) => {
                state.question_loading = false;
                state.error = action.payload;
            })
            .addCase(questionUpdatePartialAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(questionUpdatePartialAsync.fulfilled, (state, action: PayloadAction<ITest>) => {
                let newElement: ITest = action.payload;
                let newState: ITest[] = state.question_list?.map((el: ITest): ITest => {
                    if (el.id === newElement.id) {
                        return newElement;
                    }
                    return el;
                });
                state.questions_action = {
                    status: ACTION_TEST_EDIT.UPDATE,
                    id: Number(newElement.id),
                };
                state.question_list = newState;
                state.question_loading = false;
            })
            .addCase(questionUpdatePartialAsync.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.question_loading = false;
            })
            .addCase(questionDeleteAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(questionDeleteAsync.fulfilled, (state, action) => {
                let id: number = action.meta.arg;
                let newState: ITest[] = state.question_list?.filter((el) => el.id !== id);
                state.question_list = newState;

                let filterQuestions = state.current_cource?.question_ids?.filter((el: ICource) => el.id !== id);

                state.current_cource.question_ids = filterQuestions;
                state.question_loading = false;
                state.questions_action = {
                    status: ACTION_TEST_EDIT.DELETE,
                    id: id,
                };
            })
            .addCase(questionDeleteAsync.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.question_loading = false;
            })
            .addCase(getQuestionItemAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(getQuestionItemAsync.fulfilled, (state, action: PayloadAction<ITest>) => {
                state.current_question = action.payload;
                state.question_loading = false;
            })
            .addCase(getQuestionItemAsync.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.question_loading = false;
            })
            .addCase(setAnswersForResultAsync.pending, (state) => {
                state.question_loading = true;
            })
            .addCase(setAnswersForResultAsync.fulfilled, (state, action: PayloadAction<IResultTest>) => {
                let { result, course, id } = action.payload;

                state.result = result;
                state.current_cource = { ...state.current_cource, test_result: result, test_result_id: id };
                let actualState = state.courses.map((el: ICource) => {
                    if (el.id === course) {
                        return { ...el, test_result: result, test_result_id: id };
                    }
                    return el;
                });
                state.courses = actualState;
                state.question_loading = false;
                state.current_question = null;
            })
            .addCase(setAnswersForResultAsync.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.question_loading = false;
                state.current_question = null;
            });
    }
})


const { actions, reducer } = courcesReducer;
export const {
    clearActiveCource,
    setCreateModalHandler,
    testModalHandler,
    onFinishTest,
    setAnswers,
    setActiveIndex,
    setActiveItem,
    setQuestionListCards,
    clearQuestionActive
} = actions;
export default reducer;