import axios from "axios";
import { CVAT_URL, urls } from "config/config";
import { IRegistrationUser, ILoginUser, IConfirmRegistration, IActivationEmail, ICheckUserName, IRegistrationResponse } from "config/interfaces";
import { $api } from "services";

export default class AuthService {

    static async registrtion(user: IRegistrationUser) {
        let response = await $api.post<IRegistrationResponse>(`${urls.auth}/user/`, user);
        return response.data
    }

    static async login(user: ILoginUser) {
        let response = await $api.post(`${urls.auth}/login/`, user);
        return response.data
    }

    static async logout() {
        let response = await $api.post(`${urls.auth}/logout/`);
        return response.data
    }

    static async self() {
        let response = await $api.get(`${urls.auth}/user/me/`);
        return response.data
    }

    static async activationEmailAfterRegistration(data: IActivationEmail) {
        let response = await $api.post(`${urls.auth}/user/activation/`, data);
        return response.data
    }

    static async resendEmail(email: string) {
        let response = await $api.post(`${urls.auth}/user/reset_password/`, { email });
        return response.data
    }

    static async getTermsForRegistration() {
        let response = await $api.options(`${urls.auth}/user/`);
        return response.data
    }

    static async resetPasswordConfirm(data: IConfirmRegistration) {
        let response = await $api.post(`${urls.auth}/user/reset_password_confirm/`, data);
        return response.data
    }

    static async resendActivationEmail(email: string) {
        let response = await $api.post(`${urls.auth}/user/resend_activation/`, { email });
        return response.data
    }

    static async check_username(user_name: string) {
        let response = await axios.get<ICheckUserName>(`${CVAT_URL}api/v1/auth/check-username?username=${user_name}`);
        return response.data
    }

    static async google_auth(access_token: string) {
        let response = await $api.post<{key: string}>(`${urls.auth}/oauth-login/google-oauth2/`, {access_token});
        return response.data
    }
    static async getOneTimeToken() {
        let response = await $api.get<{one_time_token: string}>(`${urls.auth}/one-time-token/`);
        return response.data
    }
}