import { memo } from 'react'
import { JOB_TYPE } from 'config/enums';

type Props = {
    name: string;
    reward_per_object: { [key: string]: number };
}

const TaskItemDetailTitle = ({ name, reward_per_object }: Props): JSX.Element => {

    const jobType = (): string => {
        let result: string = '';
        let price = reward_per_object;
        let types: string[] = [];

        if (!price) return result;


        for (const key in price) {
            const element = price[key];
            if (element > 0) {
                types.push(key.split('_')[0])
            }
        }


        if (types.includes(JOB_TYPE.TRACK)) {
            result = JOB_TYPE.TRACK
            return result
        }
        if (types.includes(JOB_TYPE.SHAPE)) {
            result = JOB_TYPE.SHAPE
            return result
        }

        return result
    }

    return (
        <h1>{name} <mark style={{ fontSize: 16 }}><small>{jobType()}</small></mark></h1>
    )
}

export default memo(TaskItemDetailTitle)