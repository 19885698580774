import { combineReducers, configureStore } from "@reduxjs/toolkit";
import accountReducer from "./account/account-reducer";
import authReducer from "./auth/auth-reducer";
import errorReducer from "./error/error-reducer";
import onboardingReducer from "./onboarding/onboarding-reducer";
import userReducer from "./user/user-reducer";
import courcesReducer from "./cources/cources-reducer";
import verificationReducer from "./verification/verification-reducer";
import payoutReducer from "./payout/payout-reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    account: accountReducer,
    error: errorReducer,
    onboarding: onboardingReducer,
    cources: courcesReducer,
    verification: verificationReducer,
    payout: payoutReducer
})


export const setupStore = (): any => {
    return configureStore({
        reducer: rootReducer
    })
}


export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]