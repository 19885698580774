import { memo } from 'react';

interface IAlert {
    message: React.ReactNode;
    type?: 'success' | 'info' | 'warning' | 'error';
    description?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

const PlatformAlert = ({ message, type = 'info', className, style, description }: IAlert): JSX.Element => {

    const classes: string = `${['alert', type, className].join(' ')}`;

    if (!message) return <></>

    return (
        <div
            style={style}
            className={classes}
        >
            <p className="alert_message">{message}</p>
            {description && <p className="alert_description">{description}</p>}
        </div>
    )
}


export default memo(PlatformAlert);