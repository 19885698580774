import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";

interface IProps {
    callback: (page: number) => void;
    paramsActive?: boolean;
}
interface IFinish {
    paginationPage: number;
    setPaginationPage: (page: number) => void;
    defaultPage: string | null
}

const usePagination = ({ callback, paramsActive = true }: IProps): IFinish => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationPage, setPaginationPage] = useState<number>(() => getDefaultCount());

    function call(): void {
        paramsActive && setSearchParams({ page: `${paginationPage}` })
        if (callback) {
            callback(paginationPage || 1)
        }
    }

    useEffect(() => {
        let prevParams: string | null = searchParams.get("page");

        if (typeof prevParams === "string") {
            if (+prevParams !== paginationPage) {
                call()
            }
            return
        }
        if (prevParams === null || prevParams === undefined) {
            call()
        }
    }, [paginationPage])


    function getDefaultCount(): number {
        let defaultPage = searchParams.get("page");
        return defaultPage ? +defaultPage : 1
    }

    return { paginationPage, setPaginationPage, defaultPage: searchParams.get("page") } as IFinish

}

export default usePagination;