import React, { memo } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config/enums';
import { PlatformBtn } from 'components/ui';

const NotVerified: React.FC<{ loading: boolean; onCloseModal: () => void, showAction?: boolean }> = ({ loading, onCloseModal, showAction = true }): JSX.Element => {

    const { t } = useTranslation();

    return (
        <div className="job_unverified">
            <h1>{t('DECLINE.SORRY')}</h1>
            <h2>{t('DECLINE.NOT_VERIF')}</h2>
            <p>{t('DECLINE.PLEASE')} <Link to={ROUTES.VERIFICATION_CENTER}>{t('DECLINE.VERIFI')} </Link>  {t('DECLINE.ACC')} </p>
            {showAction && <PlatformBtn
                type="danger"
                loading={loading}
                onClick={onCloseModal}
            >
                {t('BUTTON.BACK')}
            </PlatformBtn>}
        </div>
    )
}

export default memo(NotVerified)
