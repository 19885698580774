import { Modal } from 'antd';
import { JOB_STATUSES, VERIFICATION_STATUS } from 'config/enums';
import { IJob } from 'config/interfaces';
import { NotVerified, TaskItemDetailStatusAction, TaskItemDetailInformation, TaskItemDetailUnassign } from 'components/task'
import { PlatformLoader } from 'components/ui';

interface IProps {
    loading: boolean;
    linkClick: boolean;
    onCloseModal: () => void;
    takeJob: () => void;
    onLinkClick: () => void;
    checkItIsMyJob: (jobID: any) => boolean;
    current_job: IJob | null;
    jobsList: IJob[];
    isModalVisible: boolean;
    refuseJob: () => void;
    verification_status: string;
    isHistory: boolean;
}

const TaskItemDetail: React.FC<IProps> = ({ loading, onCloseModal, takeJob, linkClick, onLinkClick, checkItIsMyJob, current_job, jobsList, isModalVisible, refuseJob, verification_status, isHistory }) => {

    if (current_job === null) {
        return <PlatformLoader />
    }

    let myJob = checkItIsMyJob(current_job.id) || current_job.status === JOB_STATUSES.COMPLETED;
    let jobName = jobsList.filter(el => el.id === current_job.id);

    let block_content = verification_status === VERIFICATION_STATUS.UNVERIFIED || verification_status === VERIFICATION_STATUS.DECLINE;

    if (current_job.draft === true) {
        block_content = false
    }

    return (
        <Modal
            key="job_detail"
            closable={true}
            onCancel={onCloseModal}
            open={isModalVisible}
            footer={null}
            width={600}
        >
            {
                block_content
                    ? <NotVerified loading={loading} onCloseModal={onCloseModal} />
                    : <div className="job_detail">
                        <TaskItemDetailInformation jobName={jobName} current_job={current_job} onLinkClick={onLinkClick} />
                        <TaskItemDetailStatusAction myJob={myJob} onCloseModal={onCloseModal} current_job={current_job} loading={loading} takeJob={takeJob} linkClick={linkClick} />
                        {(myJob && !isHistory) && <TaskItemDetailUnassign current_job={current_job} loading={loading} refuseJob={refuseJob} />}
                    </div>
            }

        </Modal>
    )
}

export default TaskItemDetail;