import { memo } from 'react'
import { LOGO_DARK_ICON } from '../../utils/icons'

const LogoDark = (props: any): JSX.Element => {
    return <div className="header_wrap_logo">
        <span className="beta">beta</span>
        <LOGO_DARK_ICON
            style={{ maxWidth: 190, maxHeight: 40 }}
            {...props}
        />
    </div>
}

export default memo(LogoDark)