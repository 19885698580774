import "./technikal-maintenance.scss";
import TECH_IMAGE from "./assets/tech.jpg";
import SETTINGS_IMAGE from "./assets/settings.png";

const TechnikalMeintenance = (): JSX.Element => {
    return (
        <div className="tech">
            <div className="tech_wrapper">
                <img className="tech_settings_1" src={SETTINGS_IMAGE} alt="Settings" />
                <img className="tech_settings_2" src={SETTINGS_IMAGE} alt="Settings" />
                <img className="tech_settings_3" src={SETTINGS_IMAGE} alt="Settings" />
                <img className="tech_settings_4" src={SETTINGS_IMAGE} alt="Settings" />
                <h1>Technical maintenance</h1>
                <p>Platform is under maintenance now. It will be available as soon as possible.</p>
                <img className="tech_img" src={TECH_IMAGE} alt="Technikal meintenance" />
            </div>
        </div>
    );
};

export default TechnikalMeintenance;
