import { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { ROUTES } from 'config/enums'
import { COURSE_TITLE_LIMIT } from 'config/config';
import { useAppSelector } from 'hooks';
import { checkCompletedCourse } from 'utils';
import CourceCompletedElement from './cource-completed-element';

const CourceNavigationPanel: React.FC = () => {

    const { courses } = useAppSelector(state => state.cources);

    let color = useCallback((result: number | null): string => {
        if (result === null) {
            return '#dedede'
        }
        let completed = checkCompletedCourse(result);

        if (completed) {
            return '#00955f'
        }

        return '#dedede'
    }, [courses])

    return (
        <>
            {
                courses.map(el => {
                    return (
                        <NavLink
                            key={el.id}
                            to={`${ROUTES.COURCES}/${el.id}`}
                            className="course_navigation_element"
                        >
                            <CheckCircleTwoTone twoToneColor={color(el.test_result)} />
                            <span>{el.name.slice(0, COURSE_TITLE_LIMIT)}</span>
                            <CourceCompletedElement completed={el?.completed} />
                        </NavLink>
                    )
                })
            }
        </>
    )
}

export default memo(CourceNavigationPanel)
