import { lazy } from 'react';

const DashboardPage = lazy(() => import('./Dashboard'));
const FAQPage = lazy(() => import('./Faq'));
const VerificationCenterPage = lazy(() => import('./VerificationCenter'));
const ConfirmEmailPage = lazy(() => import('./Auth/ConfirmEmail'));
const ResetPasswordPage = lazy(() => import('./Auth/ResetPassword'));
const RegistrationPage = lazy(() => import('./Auth/Registration'));
const LoginPage = lazy(() => import('./Auth/Login'));
const TermsPage = lazy(() => import('./Terms'));
const PrivacyPage = lazy(() => import('./Privacy'));
const MyJobsPage = lazy(() => import('./MyJobs'));
const PayoutPage = lazy(() => import('./Payout'));
const CourcesPage = lazy(() => import('./Cources/Cources'));
const CourcePage = lazy(() => import('./Cources/Cource'));
const TestEditPage = lazy(() => import('./Test/Edit'));
const AvailableJobs = lazy(() => import('./AvailableJobs'));

export { default as TestPage } from 'pages/Test/Test';

export {
    DashboardPage,
    FAQPage,
    VerificationCenterPage,
    ConfirmEmailPage,
    ResetPasswordPage,
    RegistrationPage,
    LoginPage,
    TermsPage,
    PrivacyPage,
    MyJobsPage,
    PayoutPage,
    CourcesPage,
    CourcePage,
    TestEditPage,
    AvailableJobs,
}