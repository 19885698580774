import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const HeaderRatingUser: React.FC<number | any> = ({ rating }) => {
    const { t } = useTranslation();

    return <>
        {
            rating > 3
                ? <Tag color="cyan">{t('CORE.REVIEWER')}</Tag>
                : <Tag color="gold">{t('CORE.ANNOTATOR')}</Tag>
        }
    </>
}

export default HeaderRatingUser
