import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { ROLE_USER, ROUTES } from 'config/enums';
import Courcelayout from 'components/cource/cource-layout';
import LayoutApp from 'components/layouts/Layout';
import {
    ConfirmEmailPage,
    CourcePage,
    CourcesPage,
    DashboardPage,
    FAQPage,
    LoginPage,
    MyJobsPage,
    PayoutPage,
    PrivacyPage,
    RegistrationPage,
    ResetPasswordPage,
    TermsPage,
    TestEditPage,
    VerificationCenterPage
} from 'pages';
import { PlatformLoader } from 'components/ui';
interface Iprops {
    isAuth: boolean;
}

const AppRouter: React.FC<Iprops> = ({ isAuth }) => {

    const { self } = useAppSelector(state => state.user);
    const ADMIIN = self?.groups.includes(ROLE_USER.ADMIN);

    return <>
        {
            isAuth
                ? <Routes>
                    <Route path="/" element={<LayoutApp />}>
                        <Route index path={ROUTES.DASHBOARD} element={<Suspense fallback={<PlatformLoader />}><DashboardPage /></Suspense>} />
                        <Route path={ROUTES.MONEY} element={<Suspense fallback={<PlatformLoader />}><PayoutPage /></Suspense>} />
                        <Route path={ROUTES.JOBS} element={<Suspense fallback={<PlatformLoader />}><MyJobsPage /></Suspense>} >
                            <Route path={`${ROUTES.JOBS}:sub_page`} element={<Suspense fallback={<PlatformLoader />}><MyJobsPage /></Suspense>} />
                        </Route>
                        <Route path={ROUTES.VERIFICATION_CENTER} element={<Suspense fallback={<PlatformLoader />}><VerificationCenterPage /></Suspense>} />
                        {ADMIIN && <Route path={ROUTES.TEST_EDIT + ROUTES.TEST_CREATE_PARAMS} element={<Suspense fallback={<PlatformLoader />}><TestEditPage /></Suspense>} />}
                        <Route path={ROUTES.COURCES} element={<Courcelayout />}>
                            <Route index element={<Suspense fallback={<PlatformLoader />}><CourcesPage /></Suspense>} />
                            <Route path={ROUTES.COURCES_PARAMS} element={<Suspense fallback={<PlatformLoader />}><CourcePage /></Suspense>} />
                        </Route>
                        <Route path={ROUTES.PRIVACY} element={<Suspense fallback={<PlatformLoader />}><PrivacyPage /></Suspense>} />
                        <Route path={ROUTES.TERMS} element={<Suspense fallback={<PlatformLoader />}><TermsPage /></Suspense>} />
                        <Route path={ROUTES.RESET_PASSWORD_NEW} element={<Suspense fallback={<PlatformLoader />}><ResetPasswordPage /></Suspense>} />
                        <Route path={ROUTES.FAQ} element={<Suspense fallback={<PlatformLoader />}><FAQPage /></Suspense>} />
                        <Route path="*" element={<Navigate replace to={ROUTES.DASHBOARD} />} />
                        <Route path="/" element={<Navigate replace to={ROUTES.DASHBOARD} />} />
                    </Route>
                </Routes>
                : <Routes>
                    <Route path={ROUTES.PRIVACY} element={<Suspense fallback={<PlatformLoader />}><PrivacyPage /></Suspense>} />
                    <Route path={ROUTES.TERMS} element={<Suspense fallback={<PlatformLoader />}><TermsPage /></Suspense>} />
                    <Route path={ROUTES.LOGIN} element={<Suspense fallback={<PlatformLoader />}><LoginPage /></Suspense>} />
                    <Route path={ROUTES.REGISTRATION} element={<Suspense fallback={<PlatformLoader />}><RegistrationPage /></Suspense>} />
                    <Route path={ROUTES.RESET_PASSWORD_NEW} element={<Suspense fallback={<PlatformLoader />}><ResetPasswordPage /></Suspense>} />
                    <Route path={ROUTES.RESET_PASSWORD} element={<Suspense fallback={<PlatformLoader />}><ResetPasswordPage /></Suspense>} />
                    <Route path={ROUTES.CONFIRM_EMAIL} element={<Suspense fallback={<PlatformLoader />}><ConfirmEmailPage /></Suspense>} />
                    <Route path={ROUTES.CHECK_REGISTRATION_EMAIL} element={<Suspense fallback={<PlatformLoader />}><LoginPage /></Suspense>} />
                    <Route path="*" element={<Navigate replace to={ROUTES.LOGIN} />} />
                </Routes>
        }
    </>
}

export default AppRouter
