
import { NavLink, useLocation } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import { IUser } from 'config/interfaces';
import { ROUTES } from 'config/enums';
import { ONBOARDING_DATA } from 'config/onboarding-steps-info';
import { LogoDark } from 'components/ui';
import { Language } from 'components';
import DropDownHeader from '../DropDownHeader';
import HeaderRate from './HeaderRate';
import HeaderMenu from './HeaderMenu';

const { Header } = Layout;

interface IProps {
    self: IUser,
    width: number,
    logout: () => void;
    restartOnboarding: () => void
}

const DesktopHeader: React.FC<IProps> = ({ self, logout, restartOnboarding }) => {

    let { pathname } = useLocation();

    return (
        <Header className="header" >
            <Row justify="space-between" align="middle" className="container">
                <Col
                    lg={3}
                    xl={4}
                    className="header_logo">
                    {pathname === '/'
                        ? <a className="logo" href="/"><LogoDark /> </a>
                        : <NavLink className="logo" to={ROUTES.DASHBOARD}>
                            <LogoDark />
                        </NavLink>}
                </Col>

                <Col md={8} lg={12} xl={8}>
                    <div className="header_nav">
                        <HeaderMenu />
                    </div>
                </Col>
                <Col className={"header_right" + ' ' + `${ONBOARDING_DATA.STEP_5.target}`} lg={6} xl={5}>
                    <HeaderRate rating={self.level} />
                    <Language />
                    <DropDownHeader self={self} logout={logout} restartOnboarding={restartOnboarding} />
                </Col>
            </Row>
        </Header>
    )
}


export default DesktopHeader;

