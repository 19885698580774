import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationService from '../../services/verification-service';
import { IDataUpdateVerification, IDelteDocumentInformation, IFormDataVerification, IW8FormData } from '../../config/interfaces';

export const getCountryListAsync = createAsyncThunk(
    'account/country',
    async (_, thunkApi) => {
        try {
            let response = await VerificationService.getCountryList();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getPersonalDataAsync = createAsyncThunk(
    'verification/personalData',
    async (_, thunkApi) => {
        try {
            let response = await VerificationService.personalData();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const sendPersonalDataAsync = createAsyncThunk(
    'verification/send-personalData',
    async (data: IFormDataVerification, thunkApi) => {
        try {
            let response = await VerificationService.sendPersonalData(data);
            return response
        } catch (error: any) {
            // let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

export const updatePersonalDataAsync = createAsyncThunk(
    'verification/update-personalData',
    async (data: IDataUpdateVerification, thunkApi) => {
        try {
            let response = await VerificationService.updatePersonalData(data);
            return response
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

export const unverifiedStatusAsync = createAsyncThunk(
    'verification/unverified',
    async (user_id: number, thunkApi) => {
        try {
            let response = await VerificationService.unverifiedStatus(user_id);
            return response
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)
export const uploadVerificationFileAsync = createAsyncThunk(
    'verification/upload-verification-file',
    async (formData: FormData, thunkApi) => {
        try {
            let response = await VerificationService.uploadFile(formData);
            return response
        } catch (error: any) {
            // throw new Error(error.response.data)
            throw thunkApi.rejectWithValue(error.response.data)
        }
    }
)
export const getVerificationFileAsync = createAsyncThunk(
    'verification/get-verification-file',
    async (doc_name_field: string, thunkApi) => {
        try {
            let response = await VerificationService.getFile(doc_name_field);
            return response
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)


export const deleteDocumentFileAsync = createAsyncThunk(
    'verification/delete-document-file',
    async (doc_name_field: string, thunkApi) => {
        try {
            let response = await VerificationService.deleteDocumentFile(doc_name_field)
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)
export const uploadW8FormAsync = createAsyncThunk(
    'verification/upload-w8-form',
    async (w8_form: FormData, thunkApi) => {
        try {
            let response = await VerificationService.uploadW8Form(w8_form)
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            throw thunkApi.rejectWithValue(errorList)
        }
    }
)