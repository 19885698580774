import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "hooks";
import { onFinishTest } from "store";
import { TEST_GOOD_ICON, TEST_SORRY_ICON } from "utils";
import { PlatformBtn } from 'components/ui';


interface IProps {
    result: number;
    current_course_name: string;
}

const TestFinish = ({ result, current_course_name }: IProps): JSX.Element => {

    let result_status = (result * 100) > 80 ? true : false;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    function onFinish(): void {
        dispatch(onFinishTest())
    }

    return (
        <div className="finish-test">
            {
                result_status ? <TEST_GOOD_ICON /> : <TEST_SORRY_ICON />
            }
            <h1>{
                result_status ? t('COURSE.TEST_FINISH.CONGRATULATIONS') : t('COURSE.TEST_FINISH.OOPS')
            }</h1>
            <h2>{t('COURSE.TEST_FINISH.YOU_COMPLETED')} {current_course_name} {t('COURSE.TEST_FINISH.SCORE')}</h2>
            <h1>{result * 100}%</h1>
            {
                result_status
                    ? <p>{t('COURSE.TEST_FINISH.DESCRIPTION')}</p>
                    : <p>{t('COURSE.TEST_FINISH.DESCRIPTION_2')}</p>
            }
            <PlatformBtn
                onClick={onFinish}
            >{t('COURSE.TEST_FINISH.BACK')}</PlatformBtn>
        </div>
    )
}

export default TestFinish