import { Form, Input } from "antd";
import { useTranslation } from 'react-i18next';
import { ANSWERS_LIST, MAX_LENGTH_FOR_TEST } from 'config/config';

const TestFormAnswers = ({ item, name }: any): JSX.Element => {

    const { t } = useTranslation()

    return (
        <>
            {
                ANSWERS_LIST.map((element: any) => {
                    return (
                        <Form.Item
                            key={element}
                            className="createTest_block_answer"
                            label={<b>{element.toUpperCase()}</b>}
                            name={[name, `answer_${element}`]}
                            rules={[{ required: true, message: `${t('COURSE.COURSE_FORM.VALID_1')}  ${element}!` }]}
                            initialValue={item[`answer_${element}`] ?? ''}
                        >
                            <Input maxLength={MAX_LENGTH_FOR_TEST} placeholder={element.toUpperCase()} />
                        </Form.Item>)
                })
            }
        </>
    )
}

export default TestFormAnswers
