import { ROLE_USER } from "../config/enums";
import { IUser } from "../config/interfaces";

export function constructorRowsForInfo(rows: any, results: any, self: IUser | null, historyJob: boolean): void {

    // --Annotator completed fields
    //Objects
    // reward per object
    // reward sum
    // issues
    // penalty
    // final reward

    // --Annotator-progress fields
    //Objects
    // reward per object
    // reward sum

    // --Reviewer completed fields
    //Objects
    // reward per object
    // reward sum
    // final reward

    // --reward progress fields
    //Objects
    // reward per object
    // reward sum

    function rewardPerObject(): any {
        if (Boolean(results?.reward_per_object)) {
            return {
                key: "___reward_per_object",
                label: "Reward per object",
                rectangle: `${results?.reward_per_object.track_rectangle} / ${results?.reward_per_object.shape_rectangle}`,
                polygon: `${results?.reward_per_object.track_polygon} / ${results?.reward_per_object.shape_polygon}`,
                polyline: `${results?.reward_per_object.track_polyline} / ${results?.reward_per_object.shape_polyline}`,
                points: `${results?.reward_per_object.track_points} / ${results?.reward_per_object.shape_points}`,
                cuboid: `${results?.reward_per_object.track_cuboid} / ${results?.reward_per_object.shape_cuboid}`,
                total: ``,
            }
        }
    }

    function sumReward(): void {
        rows.push({
            key: "___rewards",
            label: "Reward Sum",
            rectangle: `${results?.statistic_rewards.track_rectangle}$ / ${results?.statistic_rewards.shape_rectangle}$`,
            polygon: `${results?.statistic_rewards.track_polygon}$ / ${results?.statistic_rewards.shape_polygon}$`,
            polyline: `${results?.statistic_rewards.track_polyline}$ / ${results?.statistic_rewards.shape_polyline}$`,
            points: `${results?.statistic_rewards.track_points}$ / ${results?.statistic_rewards.shape_points}$`,
            cuboid: `${results?.statistic_rewards.track_cuboid}$ / ${results?.statistic_rewards.shape_cuboid}$`,
            total: `${results?.statistic_rewards?.sum_rewards} $`,
        })
    }

    let annotator = self?.groups.includes(ROLE_USER.ANNOTATOR);
    let reviewer = self?.groups.includes(ROLE_USER.REVIEWER);

    if (Boolean(results?.statistic_objects)) {
        rows.push({
            key: "___objects",
            label: "Objects",
            rectangle: `${results?.statistic_objects.track_rectangle} / ${results?.statistic_objects.shape_rectangle}`,
            polygon: `${results?.statistic_objects.track_polygon} / ${results?.statistic_objects.shape_polygon}`,
            polyline: `${results?.statistic_objects.track_polyline} / ${results?.statistic_objects.shape_polyline}`,
            points: `${results?.statistic_objects.track_points} / ${results?.statistic_objects.shape_points}`,
            cuboid: `${results?.statistic_objects.track_cuboid} / ${results?.statistic_objects.shape_cuboid}`,
            total: `${results?.statistic_objects.total_objects}`,
        })
    }

    if (annotator) {
        rewardPerObject()

        if (Boolean(results?.statistic_rewards)) {
            sumReward();

            if (historyJob) {
                rows.push({
                    key: "__issues",
                    label: "Issues",
                    rectangle: "",
                    polygon: "",
                    polyline: "",
                    points: "",
                    cuboid: "",
                    total: `${results?.statistic_objects?.issues_quantity} `
                })

                rows.push({
                    key: "__penalty",
                    label: "Penalty",
                    rectangle: "",
                    polygon: "",
                    polyline: "",
                    points: "",
                    cuboid: "",
                    total: `${results?.statistic_rewards?.issues_penalty} $`
                })

                rows.push({
                    key: "__final_reward",
                    label: "Final reward",
                    rectangle: "",
                    polygon: "",
                    polyline: "",
                    points: "",
                    cuboid: "",
                    total: `${results?.statistic_rewards?.total_rewards} $`
                })
            }


        }


    }

    if (reviewer) {
        rewardPerObject();
        sumReward();

        if (historyJob) {
            rows.push({
                key: "__final_reward",
                label: "Final reward",
                rectangle: "",
                polygon: "",
                polyline: "",
                points: "",
                cuboid: "",
                total: `${results?.statistic_rewards?.total_rewards} $`
            })
        }
    }
}