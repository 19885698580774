import { Button } from "antd";
import { PlatformLoader } from "components/ui";
import { ITest } from "config/interfaces";


interface IProps {
    current_question: any;
    activeItem: string;
    setActiveItem: (label: string) => void;
    loading: boolean;
}

const TestContentAnswers = ({ current_question, activeItem, setActiveItem, loading }: IProps):JSX.Element => {

    function getAnswers(test: ITest): JSX.Element {

        type TestKeys = keyof ITest;
        let testKeys: any = Object.keys(test);

        return testKeys
            .map((el: TestKeys) => {
                if (Object.getOwnPropertyDescriptor(test, el) && el.includes('answer_')) {
                    return <QuestionItem
                        key={el}
                        activeClass={activeItem === el[el.length - 1]}
                        label={el[el.length - 1]}
                        answer={test[el]}
                        setActiveItem={setActiveItem}
                    />
                }
            })
    }

    return (
        <div className="test_main">
            {
                loading || !current_question
                    ? <PlatformLoader />
                    : <>
                        <h2>{current_question.question}</h2>
                        {current_question.picture && <img src={current_question.picture} alt="question" />}
                        <div className="test_main_answers">
                            {getAnswers(current_question)}
                        </div></>
            }

        </div>
    )
}

export default TestContentAnswers;

interface IPRopsQuestion {
    label: string,
    answer: any,
    setActiveItem: (label: string) => void,
    activeClass: boolean
}


const QuestionItem = ({ label, answer, setActiveItem, activeClass }: IPRopsQuestion):JSX.Element => {

    return (
        <Button
            onClick={() => setActiveItem(label)}
            className={`checkbox_btn ${activeClass && 'active'}`}
            size="large"
        >
            <b>{label.toUpperCase()}</b>
            <span>{answer}</span>
        </Button>
    )
}
