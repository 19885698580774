import { CVAT_URL } from 'config/config'
import { IJob } from 'config/interfaces';
import { useOneTimeToken } from 'hooks';

interface IProps {
    current_job: IJob | null;
    linkRef: any;
}

const TaskItemActiveJobLink = ({ current_job, linkRef }: IProps): JSX.Element => {

    let URL = `${CVAT_URL}tasks/${current_job?.task_id}/jobs/${current_job?.id}`
    const { modifiedLink } = useOneTimeToken({ link: URL })


    return (
        <>
            {
                current_job && <a
                    href={modifiedLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'none' }}
                    ref={linkRef}
                >a</a>
            }
        </>
    )
}

export default TaskItemActiveJobLink