import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { PlatformAlert } from "components/ui"
import { ROUTES, VERIFICATION_STATUS } from "config/enums"
import { memo } from "react"

const NotificationsUi = ({ verification_status }: { verification_status: string }): JSX.Element => {

    const { t } = useTranslation()

    return (
        <>
            {
                verification_status === VERIFICATION_STATUS.UNVERIFIED && <PlatformAlert
                    style={{ marginBottom: 10 }}
                    message={
                        <span>{t('ALERTS.ACCOUNT')}<Link to={ROUTES.VERIFICATION_CENTER} style={{ marginLeft: 5 }}>{t('OTHER.HERE')}</Link>.</span>
                    }
                    type="info"
                />
            }
        </>
    )
}

export default memo(NotificationsUi)