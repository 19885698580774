import { createSlice } from "@reduxjs/toolkit";
import { ErrorType } from "../../config/interfaces";

interface IErrorState {
    errorTitle: ErrorType;
    errorDescription?: ErrorType;
    error?: ErrorType
}

const INITIAL_STATE: IErrorState = {
    errorDescription: null,
    errorTitle: null
}


export const errorSlice = createSlice({
    name: 'error',
    initialState: INITIAL_STATE,
    reducers: {
        setError(state, action) {
            state.errorTitle = action.payload.errorTitle;
            state.errorDescription = action.payload.errorDescription;
        },
        clearError(state) {
            state.error = null;
            state.errorTitle = null;
            state.errorDescription = null;
        }
    },
})

export const { setError, clearError } = errorSlice.actions
export default errorSlice.reducer
