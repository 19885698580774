import { CheckCircleTwoTone } from '@ant-design/icons';
import { memo } from 'react';

interface IProps {
    completed: boolean;
}

const CourceCompletedElement: React.FC<IProps> = ({ completed = false }): any => {
    return completed && <CheckCircleTwoTone twoToneColor="#00955f" style={{ marginLeft: 10, marginRight: 5 }} />
}

export default memo(CourceCompletedElement)
