import { useState, memo } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'hooks';
import { ROUTES } from 'config/enums';
import { ContactUs } from 'components';
import { LogoDark } from 'components/ui';
import FooterSocialLinks from './FooterSocialLinks';

const FooterApp: React.FC = (): JSX.Element => {

    const { width } = useWindowSize();
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const contactUsHandler = (e: React.SyntheticEvent<EventTarget>): void => {
        e.preventDefault();
        setIsModalVisible(true)
    }

    return (
        <footer className="footer">
            <ContactUs visible={isModalVisible} setVisible={setIsModalVisible} />
            <Row align="middle" className="container" justify="space-between">
                {width > 1000 && <Col xl={5} md={8} xs={8} sm={12}>
                    <LogoDark />
                </Col>}
                <Col xl={15} md={8} xs={24} sm={12} className="footer_links" >
                    <Link to={ROUTES.TERMS}>{t('FOOTER.TERMS')}</Link>
                    <Link to={ROUTES.PRIVACY}>{t('FOOTER.PRIVACY')}</Link>
                    <a onClick={contactUsHandler} href="_">{t('FOOTER.CONTACT')}</a>
                </Col>
                <FooterSocialLinks />
            </Row>
        </footer>
    )
}

export default memo(FooterApp);


