import { LoadingOutlined } from '@ant-design/icons';
import '../../styles/platform-elements.scss'

// DEFAULT - green
// DANGER - red
// GHOST - white
// PRIMARY - blue
// Link - link

interface IPropsButton {
    children: React.ReactNode;
    icon?: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    size?: 'small' | 'middle' | 'large' | 'full';
    htmlType?: 'submit' | 'button' | 'reset';
    type?: 'default' | 'danger' | 'ghost' | 'primary' | 'link';
    props?: { [key: string]: any };
    className?: string;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const PlatformBtn: React.FC<IPropsButton> = ({
    children,
    icon = null,
    disabled = false,
    loading = false,
    size = 'middle',
    htmlType = 'button',
    type = 'default',
    className,
    onClick,
    style,
    ...props
}): JSX.Element => {

    const iconNode = loading ? <LoadingOutlined /> : icon
    const classes: string = `${['button', size, type, className].join(' ')}`;
    const justify: 'none' | 'center' = icon ? 'none' : 'center';

    return (
        <button
            disabled={disabled || loading}
            className={classes}
            type={htmlType}
            style={{ ...style, justifyContent: justify }}
            onClick={onClick}
            {...props}
        >
            {iconNode}
            {children}
        </button >
    )
}

export default PlatformBtn