import { Tag } from 'antd';
import { JOB_STATUSES } from 'config/enums';
import i18n from '../i18n';

export function statusConstructor(jobStatus: string, jobAssignee: boolean): JSX.Element {

    if (jobStatus === JOB_STATUSES.VALIDATION) {
        return <Tag color="blue"><>{i18n.t('CORE.JOB_PENDING')}</></Tag>
    }

    if (jobStatus === JOB_STATUSES.COMPLETED) {
        return <Tag color="green"><>{i18n.t('CORE.JOB_COMPLETED')}</></Tag>
    }

    if (jobAssignee && jobStatus === JOB_STATUSES.ANNOTATION) {
        return <Tag color="cyan"><>{i18n.t('CORE.JOB_PROGRESS')}</></Tag>
    }

    if (jobStatus === JOB_STATUSES.ANNOTATION && jobAssignee === false) {
        return <Tag color="gold"><>{i18n.t('CORE.JOB_UNASSIGNED')}</></Tag>
    }

    return <Tag color="red"> None </Tag>

}