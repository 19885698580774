import TagManager from "react-gtm-module";
import { GTM_ID } from "config/config";

class GoogleTagManager {
    private environment: string | undefined;
    private dataLayerName: string;

    constructor(dataLayerName: string) {
        this.dataLayerName = dataLayerName;
        this.environment = process.env.REACT_APP_NODE_CONFIGURATION;
    }

    initialize() {
        if (this.environment === "production") {
            TagManager.initialize({
                gtmId: GTM_ID as string,
                dataLayerName: this.dataLayerName
            });
        }
    }
}

export default GoogleTagManager;