import { memo } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useWindowSize } from 'hooks';
import { MOBILE_WIDTH_FOR_JOBS } from 'config/config';
import { ROUTES } from 'config/enums';
import { PlatformTooltip } from 'components/ui';


interface IProps {
    show_due_date?: boolean;
}

const TaskItemDescription = ({ show_due_date = true }: IProps): JSX.Element => {

    const { width } = useWindowSize();
    const { pathname } = useLocation();
    const pathNameForDateType = `${ROUTES.JOBS}/${ROUTES.HISTORY_JOBS}`;
    const { t } = useTranslation()

    return (
        <>
            {
                width > MOBILE_WIDTH_FOR_JOBS
                    ? <Row className="task_item_description_nav" align="middle" justify="space-between">
                        <Col span={2}> {t('JOB.DESCRIPTION.TYPE')}</Col>
                        <Col span={4}>{t('JOB.DESCRIPTION.TITLE')}</Col>
                        <Col span={2}>{t('JOB.DESCRIPTION.LEVEL')}</Col>
                        <Col span={2}>{t('JOB.DESCRIPTION.REWARD')} <span style={{ marginRight: 2 }} /> <PlatformTooltip text={t('REWARD_TOOLTIP')} /></Col>
                        {show_due_date && <Col span={3}>{pathname === pathNameForDateType
                            ? t('JOB.DESCRIPTION.COMPLETION')
                            : t('JOB.DESCRIPTION.DUE')}
                        </Col>}
                        <Col span={2}>{t('JOB.DESCRIPTION.STATUS')}</Col>
                        {<Col span={3}>{t('JOB.DESCRIPTION.LINK')}</Col>}
                    </Row>
                    : null
            }

        </>
    )
}

export default memo(TaskItemDescription);