import { urls } from "config/config";
import { IFaqCategory, LanguageType } from "config/interfaces";
import { $api } from "services";
export default class UserService {

    static async self() {
        let response = await $api.get(`${urls.auth}/user/me/`);
        return response.data
    }

    static async sendStatusOnboarding(value: boolean) {
        //boolean need to string
        let response = await $api.patch(`${urls.auth}/user/onboarding/`, { onboarding: value.toString() });
        return response.data
    }

    static async updateUser(id: number, data: any) {
        let response = await $api.patch(`${urls.auth}/user/${id}/`, data);
        return response.data
    }

    static async updateLanguage(data: any) {
        let response = await $api.patch(`${urls.auth}/user/language/`, data);
        return response.data
    }

    static async getFAQ(lang: LanguageType) {
        let response = await $api.get<IFaqCategory[]>(`core/faq?language=${lang}`);
        return response.data
    }
}