import { PlatformBtn } from 'components/ui';
import { useTranslation } from 'react-i18next';

interface IProps {
    activeItem: string;
    onNextStep: () => void;
}

const TestContentFooter = ({ activeItem, onNextStep }: IProps):JSX.Element => {
    const {t} = useTranslation()
    return (
        <div className="test_footer">
            <PlatformBtn
                disabled={!activeItem}
                onClick={onNextStep}
            >{t('ONBOARDING.NEXT')}</PlatformBtn>
        </div>
    )
}

export default TestContentFooter
