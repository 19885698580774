import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRegistrationUser, ILoginUser, IConfirmRegistration, IActivationEmail } from "config/interfaces";
import { AuthService } from "services";


export const registrationAsync = createAsyncThunk(
    'auth/registration',
    async (user: IRegistrationUser, thunkApi) => {
        try {
            let response = await AuthService.registrtion(user);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data);
            let newErrorList = errorList.filter(el => typeof el === 'string')

            return thunkApi.rejectWithValue(newErrorList)
        }
    }
)

export const loginAsync = createAsyncThunk(
    'auth/login',
    async (user: ILoginUser, thunkApi) => {
        try {
            let response = await AuthService.login(user);
            return response
        } catch (error: any) {
            const errors_arr: string[] = [];
            Object.values(error.response.data).forEach((el: any) => {
                if (typeof el === 'string') {
                    errors_arr.push(el)
                } else {
                    errors_arr.push(...el)
                }
            });

            return thunkApi.rejectWithValue({ data_error: errors_arr, error_code: error.response.status })
        }
    }
)


export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async (_, thunkApi) => {
        try {
            let response = await AuthService.logout();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)

export const activationEmailAfterRegistrationAsync = createAsyncThunk(
    'auth/confirm',
    async (data: IActivationEmail, thunkApi) => {
        try {
            let response = await AuthService.activationEmailAfterRegistration(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)


export const resetPasswordSendEmailAsync = createAsyncThunk(
    'auth/resendEmail',
    async (email: string, thunkApi) => {
        try {
            let response = await AuthService.resendEmail(email);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)


export const resetPasswordAsync = createAsyncThunk(
    'auth/resetPassword',
    async (data: IConfirmRegistration, thunkApi) => {
        try {
            let response = await AuthService.resetPasswordConfirm(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)

export const resendActivationEmailAsync = createAsyncThunk(
    'auth/resendActivationEmail',
    async (email: string, thunkApi) => {
        try {
            let response = await AuthService.resendActivationEmail(email);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)
export const checkUserNameAsync = createAsyncThunk(
    'auth/user_name',
    async (user_name: string, thunkApi) => {
        try {
            let response = await AuthService.check_username(user_name);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)

export const googleAuthAsync = createAsyncThunk(
    'auth/google',
    async (access_token: string, thunkApi) => {
        try {
            let response = await AuthService.google_auth(access_token);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data);
            let newErrorList = errorList.filter(el => typeof el === 'string')

            return thunkApi.rejectWithValue(newErrorList)
        }
    }
)
export const getOneTimeTokenAsync = createAsyncThunk(
    'auth/get-one-time-token',
    async (_, thunkApi) => {
        try {
            let response = await AuthService.getOneTimeToken();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data);
            let newErrorList = errorList.filter(el => typeof el === 'string')

            return thunkApi.rejectWithValue(newErrorList)
        }
    }
)