import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateCource, ID, ITest } from "config/interfaces";
import { constructorErrorForQuestion } from "utils";
import { CourceService } from "services";


export const getAllCources = createAsyncThunk(
    'courses',
    async (_, thunkApi) => {
        try {
            let response = await CourceService.getAll();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getCourseById = createAsyncThunk(
    'course',
    async (id: ID, thunkApi) => {
        try {
            let response = await CourceService.getCourse(id);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const createCource = createAsyncThunk(
    'course/create',
    async (data: ICreateCource, thunkApi) => {
        try {
            let response = await CourceService.createCource(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const editCource = createAsyncThunk(
    'course/edit',
    async (data: any, thunkApi) => {
        try {
            let { id, values } = data
            let response = await CourceService.editCource(id, values);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const deleteCourse = createAsyncThunk(
    'course/delete',
    async (id: ID, thunkApi) => {
        try {
            let response = await CourceService.deleteCourse(id);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)


export const createQuestionAsync = createAsyncThunk(
    'cources/question/create',
    async (data: ITest[], thunkApi) => {
        try {
            let response = await CourceService.questionsCreate(data);
            return response
        } catch (error: any) {
            return thunkApi.rejectWithValue(constructorErrorForQuestion(error.response.data))
        }
    }
)

export const questionsListAsync = createAsyncThunk(
    'cources/question_list',
    async (id: ID, thunkApi) => {
        try {
            let response = await CourceService.questionsList(id);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const questionUpdatePartialAsync = createAsyncThunk(
    'cources/question/update',
    async (values: any, thunkApi) => {
        try {
            let { id, data } = values;
            let response = await CourceService.questionUpdate(id, data);
            return response
        } catch (error: any) {
            return thunkApi.rejectWithValue(constructorErrorForQuestion(error.response.data))
        }
    }
)

export const questionDeleteAsync = createAsyncThunk(
    'cources/question/delete',
    async (id: ID, thunkApi) => {
        try {
            let response = await CourceService.questionDelete(id);
            return response;
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getQuestionItemAsync = createAsyncThunk(
    'cources/question/item',
    async (id: ID, thunkApi) => {
        try {
            let response = await CourceService.getQuestionItem(id);
            return response;
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)


export const setAnswersForResultAsync = createAsyncThunk(
    'cources/question/set',
    async (data: any, thunkApi) => {
        try {
            let response = await CourceService.setAnswers(data);
            return response;
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)
