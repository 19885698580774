import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { W8_FOLDER_NAME } from "config/config";
import { IResponseW8AWS } from "config/interfaces";

export default class AWSService {
    private s3: any = null;
    private readonly S3_BUCKET: any = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
    private readonly AWS_LINK: string = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/`

    private start_s3() {
        if (this.s3) {
            return
        }

        const s3Client = new S3Client({
            region: process.env.REACT_APP_AWS_S3_REGION,
            credentials: {
                accessKeyId: String(process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID),
                secretAccessKey: String(process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY)
            }
        });
        this.s3 = s3Client;
    }

    // private get_cors() {
    //     const corsConfig = {
    //         "AllowedHeaders": ["*"],
    //         "AllowedMethods": ["GET", "PUT", "DELETE", "PATCH"],
    //         "AllowedOrigins": ["*"],
    //         "ExposeHeaders": [],
    //         "MaxAgeSeconds": 3000,
    //     };
    //     const corsRules = new Array(corsConfig);

    //     return corsRules
    // }

    // async upload_file(file: any, file_name: string, user_name: string): Promise<{ name: string, link: string } | any> {
    //     this.start_s3();

    //     let date = new Date();
    //     let y: number = date.getFullYear();
    //     let m: number = date.getMonth();
    //     let folder: string = `${y}/${m}/${user_name}`;

    //     let name: string = `${file_name}-${replaceSpaces(file.name)}`
    //     let link = `${this.AWS_LINK}${folder}/${name}`;

    //     const bucketParams = {
    //         Bucket: this.S3_BUCKET,
    //         Key: `${folder}/${name}`,
    //         Body: file,
    //         // CORSConfiguration: { CORSRules: this.get_cors() }
    //     };

    //     try {
    //         const data = await this.s3.send(new PutObjectCommand(bucketParams));
    //         if (data) {
    //             return { filename: name, url: link }
    //         }
    //     } catch (error: any) {
    //         throw new Error(error);
    //     }
    // }


    // async delete_file(file_url: string): Promise<any> {
    //     this.start_s3();

    //     let folder_with_file = this.parse_url_to_get_folder_with_filename(file_url)

    //     const bucketParams = {
    //         Bucket: this.S3_BUCKET,
    //         Key: `${folder_with_file}`,
    //     };

    //     try {
    //         const data = await this.s3.send(new DeleteObjectCommand(bucketParams));
    //         return data;
    //     } catch (error: any) {
    //         throw new Error(error);
    //     }
    // }

    // async get_file(file: IVerificationFile): Promise<any> {

    //     this.start_s3();

    //     let folder_with_file = this.parse_url_to_get_folder_with_filename(file.url)

    //     const bucketParams = {
    //         Bucket: this.S3_BUCKET,
    //         Key: `${folder_with_file}`
    //     };

    //     try {
    //         const concatBuffers = (buffers: Uint8Array[]) => {
    //             const totalLength = buffers.reduce((sum, buffer) => sum + buffer.byteLength, 0)
    //             const result = new Uint8Array(totalLength)
    //             let offset = 0
    //             for (const buffer of buffers) {
    //                 result.set(new Uint8Array(buffer), offset)
    //                 offset += buffer.byteLength
    //             }
    //             return result
    //         }

    //         const streamToString = (stream: ReadableStream) => new Promise<Buffer>(async (resolve, reject) => {
    //             const reader = stream.getReader()
    //             const chunks: Uint8Array[] = []

    //             try {
    //                 while (true) {
    //                     const {
    //                         done,
    //                         value
    //                     } = await reader.read()
    //                     if (done) break
    //                     chunks.push(value!)
    //                 }
    //             } finally {
    //                 // safari (iOS and macOS) doesn"t support .releaseReader()
    //                 // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/releaseLock#browser_compatibility
    //                 reader?.releaseLock()
    //             }
    //             let data: any = concatBuffers(chunks);
    //             resolve(data)
    //         })

    //         const toBase64 = (arr: any) => {
    //             let data = btoa(
    //                 arr.reduce((data: any, byte: any) => data + String.fromCharCode(byte), "")
    //             );
    //             return data
    //         }

    //         const data = await this.s3.send(new GetObjectCommand(bucketParams));
    //         const bodyContents: any = await streamToString(data.Body);
    //         let base64IMG = toBase64(bodyContents);

    //         return base64IMG
    //     } catch (error: any) {
    //         throw new Error(error);
    //     }
    // }

    // parse_url_to_get_folder_with_filename(file_url: string): string {
    //     let folder_with_file: string = file_url.substring(this.AWS_LINK.length, file_url.length);

    //     return folder_with_file
    // }

    async upload_pdf(user_name: string, pdf_file: Blob): Promise<IResponseW8AWS> {
        this.start_s3();

        let date = new Date()
        const FOLDER_NAME = W8_FOLDER_NAME;
        const YEAR: number = date.getFullYear()
        const QUARTER = Math.floor((date.getMonth() + 3) / 3);
        const DATE = date.toISOString().split("T")[0]
        const FILE_NAME = `w8_${user_name}_${DATE}.pdf`;

        const PATH: string = `${FOLDER_NAME}/${YEAR}/${QUARTER}/${FILE_NAME}`;

        const bucketParams = {
            Bucket: this.S3_BUCKET,
            Key: PATH,
            Body: pdf_file,
            contentType: "application/pdf"
        };

        let result: IResponseW8AWS = { success: false, url: null };

        try {
            const data = await this.s3.send(new PutObjectCommand(bucketParams));
            if (data) {
                result = { success: true, url: `${this.AWS_LINK}${PATH}` }
            }
        } catch (error: any) {
            result = { success: false, url: null }
        }


        return result
    }
}