import { useState, useEffect, memo } from 'react';
import { Dropdown } from 'antd';
import { useAppSelector } from 'hooks';
import { IUser } from 'config/interfaces';
import DropDownMenu from './header/DropDownMenu';

interface IProps {
    logout: () => void;
    self: IUser;
    restartOnboarding: () => void
}

const DropDownHeader: React.FC<IProps> = ({ self, logout, restartOnboarding }) => {

    const [visible, setVisible] = useState<boolean>(false);
    const { repeat_oboarding, run } = useAppSelector(state => state.onboarding);

    useEffect(() => {
        if (run) {
            setTimeout(() => {
                setVisible(true)
            }, 1000)

            return
        }
        setVisible(false)
    }, [run, repeat_oboarding])

    return <>
        <div>
            <Dropdown
                onOpenChange={setVisible}
                open={visible}
                overlay={<DropDownMenu logout={logout} self={self} restartOnboarding={restartOnboarding} />}
                placement="bottom"
                arrow
            >
                <a href="_" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <div className="header_user_info">
                        {self.username[0].toUpperCase()}
                        {self.username[1].toUpperCase()}
                    </div>
                </a>
            </Dropdown>
        </div>
    </>
}


export default memo(DropDownHeader)