import { FC } from 'react'

const BlocksWrapper: FC<any> = ({ children, className = '' }): JSX.Element => {
    return (
        <div className={`wrapper_for_blocks ${className}`}>
            {children}
        </div>
    )
}

export default BlocksWrapper
