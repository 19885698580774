import React, { useState } from 'react';
import ContactUs from './contact-us/contact-us';
import PlatformBtn from './ui/PlatformBtn';
import { useTranslation } from 'react-i18next';
import { DECLINE_ICON } from '../utils/icons';


const CheckDeclineStatus: React.FC<{ status: boolean }> = ({ status }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const { t } = useTranslation()

    const contactUsHandler = (): void => setVisible(!visible);

    return (
        <>
            {status && <div className="decline">
                <div className="decline_content">
                    <h1>{t('DECLINE.H1')}</h1>
                    <DECLINE_ICON className="decline_content_img" />
                    <h6>{t('DECLINE.H2')}</h6>
                    <h6>{t('DECLINE.H2_SUB')}</h6>
                    <PlatformBtn size="large" style={{ margin: '0 auto' }} onClick={contactUsHandler}>
                        {t('FOOTER.CONTACT')}
                    </PlatformBtn>
                </div>
            </div>}
            <ContactUs visible={visible} setVisible={setVisible} />
        </>
    )
}

export default CheckDeclineStatus
