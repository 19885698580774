import { useRef } from 'react';
import { Col } from 'antd';
import { dateConstructorForJob } from 'utils';


interface IDateProps {
    due_date: string | null;
    date_completed: string | undefined;
    mobile?: boolean;
}

const TaskItemDate = ({ due_date, date_completed, mobile = false }: IDateProps): JSX.Element => {

    const time = useRef<string>('');

    if (due_date) {
        time.current = due_date
    }

    if (!due_date && !date_completed) {
        time.current = ''
    }

    if (!due_date && date_completed) {
        time.current = date_completed
    }

    return (
        <>
            {time.current && <Col span={mobile ? 5 : 3}><span className="task_item_title">{dateConstructorForJob(new Date(time.current))}</span></Col>}
            {time.current === '' && <Col span={mobile ? 5 : 3}><span className="task_item_title">-- -- --</span></Col>}
        </>
    )
}

export default TaskItemDate