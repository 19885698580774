import { IJob } from "config/interfaces"

export function updateJobStateAfterChangesJob(el: IJob, payload: any):IJob {
    if (el.id === payload.id) {
        return {
            ...el,
            assignee_id: payload.assignee ? payload.assignee.id : null,
            reviewer_id: payload.reviewer ? payload.reviewer.id : null
        }
    }
    return el
}