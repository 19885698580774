import { apiEndpoint } from '../utils/apiEndpoint';
import PAYOUT_VIDEO from '../assets/video/payout.mp4'
import DETECTION from '../assets/annotations/object_detection.png'
import SEGMENTATION from '../assets/annotations/object_segmentation.png'
import TRACKING from '../assets/annotations/object_tracking.jpg'
import OCR from '../assets/annotations/ocr.png'
import OTHER from '../assets/annotations/other.png'

export const URL = apiEndpoint();
export const CVAT_URL = process.env.REACT_APP_HOSTNAME_CVAT;
export const TOKEN = 'token';
export const ONE_TIME_TOKEN = 'one_time_token';
export const ONE_TIME_TOKEN_TIMESTAMP = 'one_time_token_timestamp';
export const MARGIN_TOP_MAIN_LAYOUT = 75;
export const INPUT_AUTH_SIZE = 'middle';
export const GOOGLE_SITE_KEY = window.Cypress ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : process.env.REACT_APP_GOOGLE_SITE_KEY_CAPTCHA;
export const COUNT_RATE = 3;
export const PROGRESS = {
    strokeColor: "#00955f",
    trailColor: "#C1FFE9"
}
export const DURATION_ERROR = 6;
export const MOBILE_WIDTH_FOR_JOBS = 880;
export const ANSWERS_LIST = ['a', 'b', 'c', 'd'];
export const LANGUAGE = {
    EN: 'eng',
    UA: 'ukr'
}
export const COMPLETED_TEST_VALUE = 0.8;
export const I18N = 'i18nextLng';
export const MAX_LENGTH_FOR_TEST = 128;
export const CONTACT_US_EMAIL = process.env.REACT_APP_CONTACT_US_EMAIL;
export const monthNamesEN = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const monthNamesUA = ["січня", "лютого", "березня", "квітня", "травня", "червня",
    "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
];
export const VERIFICATIONS_STEPS = [
    { title: 'Step 1', description: 'User data', description_ukr: 'Особисті дані' },
    { title: 'Step 2', description: 'Adress', description_ukr: 'Адреса реєстрації' },
    { title: 'Step 3', description: 'Tax ID', description_ukr: 'ІПН' },
    { title: 'Step 4', description: 'W8 form', description_ukr: 'Форма W-8' },
]
export const urls = {
    auth: 'authentication',
    account: 'account'
}

export const verification_steps_document = [
    'passport_image_1',
    'passport_image_2',
    'passport_address_page',
    'tax_number_page',
]
export const mail = process.env.REACT_APP_SUPPORT_EMAIL;
export const BLOCK_DOMEN = '.ru';
export const MY_MONEY_VIDEO = PAYOUT_VIDEO;
export const W8_FOLDER_NAME = 'w8_form';
export const SOCIAL_LINKS = {
    facebook: process.env.REACT_APP_FACEBOOK_LINK,
    youtube: process.env.REACT_APP_YOUTUBE_LINK,
    instagram: process.env.REACT_APP_INSTAGRAM_LINK,
    linkedin: process.env.REACT_APP_LINKEDIN_LINK
}
export const FORMAT_FOR_VERIFICATION_FILES = ['image/png', 'image/jpeg', 'image/jpeg', 'image/webp'];
export const COURSE_TITLE_LIMIT = 23;
export const MEDIA = {
    smartphones: 641,
    tablet: 961
}
export const WIDTH_FOR_ONBOARDING = 1000;
export const STATUS_CODE_EMAIL_IS_NOT_ACTIVATED = 499;
export const ANNOTATION_TYPE = {
    'object_detection': {
        title: 'Object Detection',
        poster: DETECTION
    },
    'object_segmentation': {
        title: 'Object Segmentation',
        poster: SEGMENTATION
    },
    'object_tracking': {
        title: 'Object Tracking',
        poster: TRACKING
    },
    'ocr': {
        title: 'OCR',
        poster: OCR
    },
    'other': {
        title: 'Other',
        poster: OTHER
    },
}
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;
export const SURVEY_LINK = process.env.REACT_APP_SURVEY_LINK;
export const VERIFICATION_FIELDS_MAX_LENGTH = 150;