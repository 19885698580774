export enum ROUTES {
    LOGIN = '/sign-in',
    REGISTRATION = '/sign-up',
    RESET_PASSWORD_NEW = '/reset_password/:uid/:token',
    RESET_PASSWORD = '/reset_password',
    CONFIRM_EMAIL = '/confirm-email',
    CHECK_REGISTRATION_EMAIL = '/sign-in/:uid/:token',
    STATISTICS = '/statistics',
    MONEY = '/money',
    DASHBOARD = '/dashboard',
    VERIFICATION_CENTER = '/verification-center',
    TEST_EDIT = '/testEdit',
    TEST_CREATE_PARAMS = '/:id',
    TEST = '/test',
    COURCES = '/cources',
    COURCES_PARAMS = ':id',
    PRIVACY = '/privacy-policy',
    TERMS = '/terms-of-use',
    JOBS = '/jobs',
    AVAILABLE = 'available',
    HISTORY_JOBS = 'history',
    ACTIVE = 'active',
    FAQ = '/faq'
}
export enum JOB_STATUSES {
    VALIDATION = 'validation',
    COMPLETED = 'completed',
    ANNOTATION = 'annotation',
}

export enum BREAKPOINTS {
    desktop = 1000,
    tablet = 768,
    mobile = 320,
}

export enum ROLE_USER {
    ADMIN = 'admin',
    ANNOTATOR = 'annotator',
    REVIEWER = 'reviewer'
}
export enum ACTION_TEST_EDIT {
    UPDATE,
    CREATE,
    DELETE
}

export enum VERIFICATION_STATUS {
    UNVERIFIED = 'unverified',
    EVALUATION = 'evaluation',
    CHECKED = 'w8 form',
    VERIFIED = 'verified',
    DECLINE = 'decline'
}

export enum STATUS_WITHDRAWAL {
    PENDING = 'pending',
    WAITING = 'waiting',
    PROCESSING = 'processing',
    CANCELLED = 'cancelled',
    PAID = 'paid',
    DECLINED = 'declined',
}

export enum JOB_TYPE {
    TRACK = 'track',
    SHAPE = 'shape'
}

export enum PAYOUT_METHODS {
    SWIFT = 'swift',
    WISE = 'wise',
    PAYONEER = 'payoneer'
}