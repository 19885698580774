import { Outlet } from 'react-router-dom'
import CourceNavigationPanel from './cource-navigation-panel'

const Courcelayout = (): JSX.Element => {
    return (
        <div className="cources">
            <div className="cources_nav">
                <CourceNavigationPanel />
            </div>
            <div className="cources_content">
                <Outlet />
            </div>
        </div>
    )
}

export default Courcelayout
