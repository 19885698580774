import { NavLink, Link } from 'react-router-dom'
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config/enums'
import { ONBOARDING_DATA } from 'config/onboarding-steps-info';
import { IUser } from 'config/interfaces';
import { WIDTH_FOR_ONBOARDING } from 'config/config';
import { useWindowSize } from 'hooks';

const { Paragraph } = Typography;

interface IProps {
    logout: () => void;
    self: IUser;
    restartOnboarding: () => void;
}

const DropDownMenu = ({ logout, self, restartOnboarding }: IProps): JSX.Element => {

    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <div style={{ width: 250 }} className="dropdown_menu" >
            <div className="dropdown_menu_self">
                <b> <span>{t('INPUT.USER_NAME_LABEL')}:</span>{self.username}</b>
                <b>
                    <span>{t('INPUT.EMAIL_LABEL')}</span>
                    <Paragraph
                        copyable={{
                            text: self.email,
                        }}>
                        <small className={self.email.length > 17 ? 'shadow' : ''}> {self.email.slice(0, 17)}</small>
                    </Paragraph>
                </b>
            </div >
            <NavLink to={ROUTES.COURCES} className={ONBOARDING_DATA.STEP_7.target} >{t('HEADER.GUIDES')}</NavLink>
            {width > WIDTH_FOR_ONBOARDING && <NavLink to={ROUTES.VERIFICATION_CENTER} className={ONBOARDING_DATA.STEP_6.target} >{t('HEADER.ACCOUNT')}</NavLink>}
            {width > WIDTH_FOR_ONBOARDING && <Link to={ROUTES.DASHBOARD} onClick={restartOnboarding} >{t('CORE.ONBOARDING')}</Link>}
            <NavLink to={ROUTES.FAQ}>FAQ</NavLink>
            <a target="_blank" rel="noreferrer" href="https://form.jotform.com/220242418095349">{t('HEADER.REPORT')}</a>
            <Button type="link" onClick={logout}>{t('HEADER.LOGOUT')}</Button>
        </div >
    )
}

export default DropDownMenu
