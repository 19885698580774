
import { useEffect } from 'react';
import { Table, Empty, Tooltip, Typography, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config/enums';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PlatformLoader, PlatformBtn } from 'components/ui';
import { timeFormat, constructorRowsForInfo } from 'utils';
import { getJobInfoAsync } from 'store';

interface IProps {
    setOpenInfo: (type: null) => void;
    jobID: number | null;
    openInfo: number | null;
}

const { Text } = Typography

const TaskItemInfo: React.FC<IProps> = ({ setOpenInfo, jobID, openInfo }): JSX.Element => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch();
    const { job_info: { loading, results } } = useAppSelector(state => state.account);
    const self = useAppSelector(state => state.user.self);
    let { pathname } = useLocation();

    const rows: any = [];
    const isHistory = pathname === `${ROUTES.JOBS}/${ROUTES.HISTORY_JOBS}`;

    useEffect(() => {
        if (jobID) {
            rows.length = 0
            dispatch(getJobInfoAsync({ id: jobID, historyJob: isHistory }))
        }
    }, [jobID, dispatch])

    const makeShapesTracksTitle = (title: string): JSX.Element => (
        <Tooltip title="Tracks / Shapes" mouseLeaveDelay={0}>
            <Text strong style={{ marginRight: 5 }}>
                {title}
            </Text>
            <QuestionCircleOutlined className="cvat-info-circle-icon" />
        </Tooltip>
    );

    const columns = [
        {
            title: '',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: makeShapesTracksTitle('Rectangle'),
            dataIndex: 'rectangle',
            key: 'rectangle',
        },
        {
            title: makeShapesTracksTitle('Polygon'),
            dataIndex: 'polygon',
            key: 'address',
        },
        {
            title: makeShapesTracksTitle('Polyline'),
            dataIndex: 'polyline',
            key: 'polyline',
        },
        {
            title: makeShapesTracksTitle('Points'),
            dataIndex: 'points',
            key: 'points',
        },
        {
            title: makeShapesTracksTitle('Cuboid'),
            dataIndex: 'cuboid',
            key: 'cuboid',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    if (results === null && loading === false) {
        return <Empty description={t('NO_DATA')} />
    }

    if (loading) {
        return <PlatformLoader />
    }

    constructorRowsForInfo(rows, results, self, isHistory)

    return (
        <Modal
            key="job_info"
            title={t('JOB.OVERVIEW')}
            className="job_info_modal"
            closable={true}
            onCancel={() => setOpenInfo(null)}
            open={openInfo ? true : false}
            width={1000}
            footer={null}
        >
            <div className="job_info">
                <h1>{t('JOB.INFO.JOB')}</h1>
                <div className="job_info_job">
                    <div>
                        <h3>Assignee</h3>
                        <p>{results?.annotator ?? '----'}</p>
                    </div>
                    <div>
                        <h3>Reviewer</h3>
                        <p>{results?.reviewer ?? '----'}</p>
                    </div>
                    <div>
                        <h3>Frames</h3>
                        <p>{results?.frames_quantity}</p>
                    </div>
                    <div>
                        <h3>Timer</h3>
                        <p>{timeFormat(results?.time_tracker ?? 0)}</p>
                    </div>
                </div>
                <h1>Annotation details</h1>
                <Table columns={columns} dataSource={rows} pagination={false} locale={{ emptyText: t('NO_DATA'), }} />
                <div className="job_info_btn">
                    <PlatformBtn
                        onClick={() => setOpenInfo(null)}
                    >
                        {t('BUTTON.OK')}
                    </PlatformBtn>
                </div>
            </div >
        </Modal>
    )
}

export default TaskItemInfo;




