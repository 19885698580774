import { useTranslation } from 'react-i18next';
import { RollbackOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { IJob } from 'config/interfaces';
import { PlatformBtn } from 'components/ui';

interface IProps {
    refuseJob: () => void;
    current_job: IJob;
    loading: boolean;
}

const TaskItemDetailUnassign = ({ current_job, loading, refuseJob }: IProps): JSX.Element => {

    const { t } = useTranslation();

    const btn = (
        <PlatformBtn
            type="danger"
            size="middle"
            loading={loading}
            onClick={refuseJob}
            disabled={current_job.draft}
            data-cy="unassign_button"
        >
            {t('BUTTON.UNASSIGN')}
        </PlatformBtn>
    )

    return (
        <div className="job_detail_item">
            <RollbackOutlined />
            <p>{t('BUTTON.UNASSIGN')}:</p>
            {current_job.draft === true
                ? <Tooltip title={t('REFUSE_DRAFT_TOOLTIP')}>
                    {btn}
                </Tooltip>
                : btn
            }
        </div>
    )
}

export default TaskItemDetailUnassign;