import { memo } from 'react'
import { IJob } from 'config/interfaces'
import { JOB_STATUSES } from 'config/enums';

interface IProps {
    job: IJob;
}

const TaskItemTitle = ({ job }: IProps): JSX.Element => {

    let { name, status, cvat_job_id, id } = job

    const TITLE = `${name} (#${status === JOB_STATUSES.COMPLETED ? cvat_job_id : id})`;

    return <>{TITLE}</>
}

export default memo(TaskItemTitle)