import { LANGUAGE, monthNamesEN, monthNamesUA } from "../config/config";
import i18n from "../i18n";

export function dateConstructorForJob(time: Date): string {

    if (!time) return "-- -- --"

    let month = i18n.language === LANGUAGE.EN
        ? monthNamesEN[time.getUTCMonth()]
        : monthNamesUA[time.getUTCMonth()];
    let day = time.getUTCDate()
    // let year = time.getUTCFullYear()

    return `${day} ${month} `
}