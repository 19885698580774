import { ICountry, IFormDataVerification, IVerificationState } from "../../config/interfaces";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERIFICATION_STATUS } from "../../config/enums";
import { deleteDocumentFileAsync, getCountryListAsync, sendPersonalDataAsync, getPersonalDataAsync, updatePersonalDataAsync, unverifiedStatusAsync, getVerificationFileAsync, uploadVerificationFileAsync, uploadW8FormAsync } from './verification-actions';

const INITIAL_STATE: IVerificationState = {
    loading: false,
    error: null,
    verification_step: 0,
    verification_form_data: {} as IFormDataVerification,
    verification_status: null,
    verification_data_response: {} as IFormDataVerification,
    isNotHaveTaxID: false,
    isMailingAddress: false,
    countries: {} as ICountry,
}

export const verificationSlice = createSlice({
    name: 'verification',
    initialState: INITIAL_STATE,
    reducers: {
        setIsMailingAddress(state, action: PayloadAction<boolean>) {
            state.isMailingAddress = action.payload;
        },
        setIsNotHaveTaxID(state, action: PayloadAction<boolean>) {
            state.isNotHaveTaxID = action.payload;
        },
        setVerificationStep(state, action: PayloadAction<number>) {
            state.verification_step = action.payload;
        },
        setVerificationFormData(state, action: PayloadAction<any>) {
            state.verification_form_data = {
                ...state.verification_form_data,
                ...action.payload
            }
        },
        clearVerification(state) {
            state.verification_data_response = {} as IFormDataVerification;
        },
        setLoadingVerificationReducer(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        clearErrorVerificationReducer(state) {
            state.error = null
        },
        setVerificationStatus(state, action: PayloadAction<string>) {
            state.verification_status = action.payload;
            state.verification_data_response = {
                ...state.verification_data_response,
                user: {
                    ...state.verification_data_response.user,
                    verification_status: action.payload,
                },
            };
            state.verification_form_data = {
                ...state.verification_form_data,
                user: {
                    ...state.verification_form_data.user,
                    verification_status: action.payload,
                },
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountryListAsync.fulfilled, (state, action: PayloadAction<ICountry>) => {
                state.countries = action.payload;
            })
            .addCase(deleteDocumentFileAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteDocumentFileAsync.fulfilled, (state, action: PayloadAction<IFormDataVerification>) => {
                state.loading = false;
                state.verification_form_data = {
                    ...state.verification_form_data,
                    passport_image_1: action.payload.passport_image_1,
                    passport_address_page: action.payload.passport_address_page,
                    tax_number_page: action.payload.tax_number_page,
                    passport_image_2: action.payload.passport_image_2,
                };
            })
            .addCase(deleteDocumentFileAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(sendPersonalDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendPersonalDataAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                // state.verification_status = payload.user.verification_status;
            })
            .addCase(sendPersonalDataAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getPersonalDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPersonalDataAsync.fulfilled, (state, action: PayloadAction<IFormDataVerification>) => {
                state.verification_form_data = action.payload;
                state.verification_data_response = action.payload;
                state.loading = false;

                const setStep = (status: string): number => {
                    switch (status) {
                        case VERIFICATION_STATUS.VERIFIED:
                            return 4;
                        case VERIFICATION_STATUS.UNVERIFIED:
                            return 1;
                        case VERIFICATION_STATUS.CHECKED:
                            return 4;
                        default:
                            return 0;
                    }
                };

                if (action.payload?.user) {
                    state.verification_status = action.payload?.user.verification_status;
                    state.verification_step = setStep(action.payload.user.verification_status);
                } else {
                    state.verification_step = 1;
                }
            })
            .addCase(getPersonalDataAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updatePersonalDataAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePersonalDataAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.verification_status = payload.user.verification_status;
                state.verification_data_response = payload;
                state.verification_form_data = {
                    ...state.verification_data_response,
                    date_signing: payload.date_signing,
                };
            })
            .addCase(updatePersonalDataAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(unverifiedStatusAsync.pending, (state) => {
                state.loading = false;
            })
            .addCase(unverifiedStatusAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.verification_status = VERIFICATION_STATUS.UNVERIFIED;
                state.verification_step = 1;
                state.verification_data_response = {
                    ...state.verification_data_response,
                    user: {
                        ...state.verification_data_response.user,
                        verification_status: VERIFICATION_STATUS.UNVERIFIED,
                    },
                };
                state.verification_form_data = {
                    ...state.verification_form_data,
                    user: {
                        ...state.verification_form_data.user,
                        verification_status: VERIFICATION_STATUS.UNVERIFIED,
                    },
                };
            })
            .addCase(unverifiedStatusAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getVerificationFileAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVerificationFileAsync.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getVerificationFileAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(uploadVerificationFileAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadVerificationFileAsync.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(uploadVerificationFileAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(uploadW8FormAsync.fulfilled, (state) => {
                const date = new Date(); 
                const date_now = date.toISOString();
                state.verification_data_response = {
                    ...state.verification_data_response,
                    user: {
                        ...state.verification_data_response.user,
                        verification_status: VERIFICATION_STATUS.VERIFIED,
                    },
                    date_signing: date_now
                };
                state.verification_form_data = {
                    ...state.verification_form_data,
                    user: {
                        ...state.verification_form_data.user,
                        verification_status: VERIFICATION_STATUS.VERIFIED,
                    },
                    date_signing: date_now
                };
            })
    }
})

export const {
    setIsMailingAddress,
    setIsNotHaveTaxID,
    setVerificationStep,
    setVerificationFormData,
    clearVerification,
    clearErrorVerificationReducer,
    setLoadingVerificationReducer,
    setVerificationStatus
} = verificationSlice.actions;
export default verificationSlice.reducer;