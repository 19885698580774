import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { TOKEN, URL } from "config/config";
import { storage } from "services";
import { store } from "../index";
import {
    setError
} from "store";
import { logoutSystem } from "store/auth/auth-reducer";

const $api = axios.create({
    baseURL: URL,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
})

$api.interceptors.request.use((config: AxiosRequestConfig) => {

    let token = storage.get(TOKEN);
    if (token) {
        config.headers = {
            Authorization: `Token ${storage.get(TOKEN)}`,
        }
        return config
    }

    return config
})

$api.interceptors.response.use((config: AxiosResponse) => {
    return config;
}, async (error) => {

    const statusError = error.response.status;

    if (statusError === 401) {
        //Unauthorized
        store.dispatch(logoutSystem())
        return
    }

    if (Array.isArray(error.response.data)) {

        store.dispatch(setError({
            errorTitle: error.message,
            errorDescription: error.response.statusText
        }));

        return Promise.reject(error);
    }

    if (typeof error.response.data === "object") {

        let errorList = Object.values(error.response.data);
        let arrayErrors: any = errorList[0];


        store.dispatch(setError({
            errorTitle: error.message,
            errorDescription: arrayErrors
        }));

        return Promise.reject(error);
    }

    if (typeof error.response.data === "string") {
        store.dispatch(setError({
            errorTitle: error.message,
            errorDescription: error.response.statusText
        }));
    }
})

export default $api;