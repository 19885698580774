import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined } from '@ant-design/icons';
import { ITestForEdit } from 'config/interfaces';
import { MAX_LENGTH_FOR_TEST } from 'config/config';

interface IProps {
    index: number;
    name: any;
    item: ITestForEdit;
    removeItem: (item: ITestForEdit) => void;
    courseID: number | null;
    loading?: boolean;
}


const TestFormBasic = ({ index, name, item, removeItem, courseID, loading = false }: IProps):JSX.Element => {

    const { t } = useTranslation()

    return (
        <>
            <h2>Question #{index + 1}</h2>
            {<Button
                className="createTest_block_delete"
                danger
                type="primary"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => removeItem(item)}
                disabled={loading}
            />}
            <Form.Item
                style={{ display: 'none' }}
                name={courseID ? [name, "course"] : [name, "id"]}
                initialValue={courseID ?? name}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={[name, "question"]}
                rules={[{ required: true, message: t('COURSE.COURSE_FORM.VALID_3') }]}
                initialValue={item.question}
            >
                <TextArea maxLength={MAX_LENGTH_FOR_TEST} rows={2} />
            </Form.Item>
            <Form.Item
                name={[name, "picture"]}
                rules={[{ type: 'url', message: t('COURSE.COURSE_FORM.VALID_2') }]}
                label={t('COURSE.COURSE_FORM.PICTURE')}
                initialValue={item.picture}
            >
                <Input placeholder={t('COURSE.COURSE_FORM.PICTURE')} />
            </Form.Item>
        </>
    )
}

export default TestFormBasic
