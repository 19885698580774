import { urls } from "config/config";
import { IFormDataVerification, ICountry, IW8FormData, IDataUpdateVerification } from "config/interfaces";
import { $api } from "services";

export default class VerificationService {

    static async getCountryList() {
        let response = await $api.get<ICountry>(`${urls.auth}/personal-data/countries/`)
        return response.data
    }

    static async personalData() {
        let response = await $api.get(`${urls.auth}/personal-data/`);
        return response.data
    }

    static async sendPersonalData(data: IFormDataVerification) {
        let response = await $api.post(`${urls.auth}/personal-data/`, data);
        return response.data
    }

    static async deletePersonalData(id: number, data: any) {
        let response = await $api.delete(`${urls.auth}/personal-data/${id}`, data);
        return response.data
    }

    static async partialUpdatePersonalData(id: number, data: any) {
        let response = await $api.patch(`${urls.auth}/personal-data/${id}`, data);
        return response.data
    }

    static async updatePersonalData(data: IDataUpdateVerification) {
        let response = await $api.patch(`${urls.auth}/personal-data/${data.id}/`, data.data);
        return response.data
    }

    static async getPersonalDataById(id: number) {
        let response = await $api.get(`${urls.auth}/personal-data/${id}`);
        return response.data
    }

    static async unverifiedStatus(user_id: number) {
        let response = await $api.get(`${urls.auth}/user/${user_id}/unverified/`);
        return response.data
    }
    static async deleteDocumentFile(doc_name_field: string) {
        let response = await $api.delete(`${urls.auth}/personal-data/document-file/${doc_name_field}/`);
        return response.data
    }
    static async getFile(doc_name_field: string) {
        let response = await $api.get<string>(`${urls.auth}/personal-data/image/${doc_name_field}/`);
        return response.data
    }
    static async uploadFile(formData: FormData) {
        let response = await $api.post<void>(`${urls.auth}/personal-data/upload-files/`, formData);
        return response.data
    }
    static async uploadW8Form(w8_form: FormData) {
        let response = await $api.post<void>(`${urls.auth}/personal-data/save-w8-file/`, w8_form);
        return response.data
    }
}