import { Steps } from "antd";
import { memo } from 'react';


const { Step } = Steps;

interface IProps {
    questionsCount: number;
    activeIndex: number;
    title: string;
}

const TestContentHeader = ({ questionsCount, activeIndex, title }: IProps): JSX.Element => {

    return (
        <div className="test_header">
            <h1>{title}</h1>
            <Steps
                size="small"
                current={activeIndex}>
                {
                    new Array(questionsCount)
                        .fill('')
                        .map((el) => <Step key={activeIndex} />)
                }
            </Steps>
        </div>
    )
}

export default memo(TestContentHeader)
